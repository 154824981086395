// External Imports
import { useState, useEffect } from "react";

// Internal Imports
import Modal from "./Modal";

const LocationModal = ({ show, hide, title, location, onSave, onDelete }) => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [bitters, setBitters] = useState(false);
  const [mixers, setMixers] = useState(false);
  const [syrups, setSyrups] = useState(false);
  const [canBuy, setCanBuy] = useState(false);
  const [canDrink, setCanDrink] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  useEffect(() => {
    if (location) {
      setName(location.name || "");
      setAddress(location.address || "");
      setCity(location.city || "");
      setState(location.state || "");
      setCountry(location.country || "");
      setZip(location.zip || "");
      setPhone(location.phone || "");
      setWebsite(location.website || "");
      setBitters(location.bitters || false);
      setMixers(location.mixers || false);
      setSyrups(location.syrups || false);
      setCanBuy(location.canBuy || false);
      setCanDrink(location.canDrink || false);
      setLatitude(location.latitude || "");
      setLongitude(location.longitude || "");
    } else {
      reset();
    }
  }, [location]);

  const reset = () => {
    setName("");
    setAddress("");
    setCity("");
    setState("");
    setCountry("");
    setZip("");
    setPhone("");
    setWebsite("");
    setBitters(false);
    setMixers(false);
    setSyrups(false);
    setCanBuy(false);
    setCanDrink(false);
    setLatitude("");
    setLongitude("");
  };

  const handleSave = () => {
    onSave({
      name,
      address,
      city,
      state,
      country,
      zip,
      phone,
      website,
      bitters,
      mixers,
      syrups,
      canBuy,
      canDrink,
      latitude,
      longitude,
    });
  };

  return (
    <Modal show={show} hide={hide} title={title || "Edit Location"} style={{ width: 400 }}>
      <div style={containerStyle}>
        <input placeholder="Name" style={inputStyle} value={name} onChange={(e) => setName(e.target.value)} />
      </div>

      <div style={containerStyle}>
        <input
          placeholder="Address"
          style={inputStyle}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
      </div>

      <div style={containerStyle}>
        <input placeholder="City" style={inputStyle} value={city} onChange={(e) => setCity(e.target.value)} />
      </div>

      <div style={containerStyle}>
        <input
          placeholder="State"
          style={inputStyle}
          value={state}
          onChange={(e) => setState(e.target.value)}
        />
      </div>

      <div style={containerStyle}>
        <input
          placeholder="Country"
          style={inputStyle}
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        />
      </div>

      <div style={containerStyle}>
        <input
          placeholder="Zip Code"
          style={inputStyle}
          value={zip}
          onChange={(e) => setZip(e.target.value)}
        />
      </div>

      <div style={containerStyle}>
        <input
          placeholder="Phone Number"
          style={inputStyle}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>

      <div style={containerStyle}>
        <input
          placeholder="Website"
          style={inputStyle}
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
        />
      </div>

      <div style={containerStyle}>
        <input
          placeholder="Latitude"
          type="number"
          style={inputStyle}
          value={latitude}
          onChange={(e) => setLatitude(e.target.value)}
        />
      </div>

      <div style={containerStyle}>
        <input
          placeholder="Longitude"
          type="number"
          style={inputStyle}
          value={longitude}
          onChange={(e) => setLongitude(e.target.value)}
        />
      </div>

      <div style={containerStyle}>
        <input type="checkbox" style={checkboxStyle} value={bitters} onChange={(e) => setBitters(!bitters)} />
        Bitters
      </div>

      <div style={containerStyle}>
        <input type="checkbox" style={checkboxStyle} value={mixers} onChange={(e) => setMixers(!mixers)} />
        Mixers
      </div>

      <div style={containerStyle}>
        <input type="checkbox" style={checkboxStyle} value={syrups} onChange={(e) => setSyrups(!syrups)} />
        Syrups
      </div>

      <div style={containerStyle}>
        <input type="checkbox" style={checkboxStyle} value={canBuy} onChange={(e) => setCanBuy(!canBuy)} />
        Can Buy
      </div>

      <div style={containerStyle}>
        <input
          type="checkbox"
          style={checkboxStyle}
          value={canDrink}
          onChange={(e) => setCanDrink(!canDrink)}
        />
        Can Drink
      </div>

      <div style={{ display: "flex", justifyContent: "space-around", marginTop: 20 }}>
        <button style={{ padding: "5px 10px", fontSize: 16 }} onClick={() => onDelete()}>
          Delete
        </button>

        <button style={{ padding: "5px 10px", fontSize: 16 }} onClick={() => handleSave()}>
          Save
        </button>
      </div>
    </Modal>
  );
};

const containerStyle = {
  // alignItems: "center",
  margin: "10px 0px",
  display: "flex",
  fontSize: 18,
};

const inputStyle = {
  width: "100%",
  fontSize: 16,
};

const checkboxStyle = {
  marginRight: 10,
  fontSize: 16,
};

export default LocationModal;
