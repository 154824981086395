// External Imports
import { useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";

// Internal Imports
import { useFilters } from "hooks/useFilters";
import KeywordPanel from "./KeywordPanel";
import KeywordModal from "./KeywordModal";
import TagPanel from "./TagPanel";
import TagModal from "./TagModal";

const Keywords = () => {
  const { keywords, saveKeyword, deleteKeyword, tags, saveTag, deleteTag } = useFilters();
  const [showAddModal, setShowAddModal] = useState(false);
  const [panel, setPanel] = useState("keywords");

  const handleSaveKeyword = (keyword) => {
    saveKeyword(keyword);
    setShowAddModal(false);
  };

  const handleDeleteKeyword = (keyword) => {
    if (keyword) {
      deleteKeyword(keyword);
    }
  };

  const handleSaveTag = (tag) => {
    saveTag(tag);
    setShowAddModal(false);
  };

  const handleDeleteTag = (tag) => {
    if (tag) {
      deleteTag(tag);
    }
  };

  return (
    <>
      {panel === "keywords" ? (
        <KeywordModal
          show={showAddModal}
          hide={() => setShowAddModal(false)}
          onSave={(keyword) => handleSaveKeyword(keyword)}
          onDelete={() => setShowAddModal(false)}
        />
      ) : (
        <TagModal
          show={showAddModal}
          hide={() => setShowAddModal(false)}
          onSave={(tag) => handleSaveTag(tag)}
          onDelete={() => setShowAddModal(false)}
        />
      )}

      <div
        style={{
          flexFlow: "column",
          display: "flex",
          width: "100%",
          padding: 30,
        }}
      >
        <div
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
            color: "white",
            fontSize: 40,
          }}
        >
          <div>
            <span style={{ opacity: panel === "keywords" ? 1.0 : 0.5 }} onClick={() => setPanel("keywords")}>
              Keywords
            </span>{" "}
            /{" "}
            <span style={{ opacity: panel === "tags" ? 1.0 : 0.5 }} onClick={() => setPanel("tags")}>
              Tags
            </span>
          </div>
          <AiOutlinePlusCircle onClick={() => setShowAddModal(true)} />
        </div>

        {panel === "keywords" ? (
          <KeywordPanel keywords={keywords} handleDelete={handleDeleteKeyword} />
        ) : (
          <TagPanel tags={tags} handleDelete={handleDeleteTag} />
        )}

        {/* <div
          style={{
            justifyContent: "space-between",
            alignContent: "flex-start",
            flexWrap: "wrap",
            overflow: "auto",
            display: "flex",
            marginTop: 30,
            flexGrow: 1,
          }}
        >
          <KeywordList title="Spirit" keywords={spiritKeywords} width={columnWidth} onDelete={handleDelete} />
          <KeywordList
            title="Characteristic"
            keywords={characteristicKeywords}
            width={columnWidth}
            onDelete={handleDelete}
          />
          <KeywordList
            title="Occasion"
            keywords={occasionKeywords}
            width={columnWidth}
            onDelete={handleDelete}
          />
          <KeywordList title="Food" keywords={foodKeywords} width={columnWidth} onDelete={handleDelete} />
          <KeywordList title="Topics" keywords={topicsKeywords} width={columnWidth} onDelete={handleDelete} />
        </div> */}
      </div>
    </>
  );
};

export default Keywords;
