// External Imports
import { Route, Navigate } from "react-router-dom";

// Internal Imports
import { useAuth } from "hooks/useAuth";

// Public Pages
import Login from "pages/Login";

const PublicRoute = ({ forwardTo = null, children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated && forwardTo ? <Navigate to={forwardTo} /> : children;
};

const publicRoutes = [
  <Route
    key="login"
    exact
    path="/"
    element={
      <PublicRoute forwardTo="/dashboard/home">
        <Login />
      </PublicRoute>
    }
  />,
];

export default publicRoutes;
