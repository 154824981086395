// External Import
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

// Internal Imports
import * as actions from "state/status/actions";

export const useStatus = () => {
  // state
  const loading = useSelector((state) => state.status.loading);
  const error = useSelector((state) => state.status.error);
  const dispatch = useDispatch();

  // functions
  const setLoading = useCallback((loading) => dispatch(actions.setLoading(loading)), [dispatch]);
  const logError = useCallback((error) => dispatch(actions.logError(error)), [dispatch]);
  const clearError = useCallback(() => dispatch(actions.clearError()), [dispatch]);

  return {
    // state
    loading,
    error,

    // functions
    setLoading,
    logError,
    clearError,
  };
};
