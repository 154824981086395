// Internal Imports
import { setLoading, logError } from "state/status/actions";
import { getProducts } from "services/shopify";
import { types } from "./types";

export const fetchProducts = () => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const products = await getProducts();
      const formattedProducts = products.map((p) => ({ ...p, id: p.id.split("/").at(-1) }));

      dispatch({
        type: types.SET_PRODUCTS,
        payload: formattedProducts,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem getting products"));
      }
    }

    dispatch(setLoading(false));
  };
};
