// External Imports
import { getFirestore, collection, deleteDoc, getDocs, setDoc, doc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Internal Imports
import { removeUndefined, generateID, toDatetime } from "utils";
import { ElGuapoError } from "errors/ElGuapoError";
import { uploadFile } from "services/storage";

export const fetchCards = async () => {
  const db = getFirestore();
  const cardsRef = await getDocs(collection(db, "CARDS")).catch((e) => {
    throw new ElGuapoError(e.message);
  });

  return cardsRef.docs.map((doc) => ({
    ...doc.data(),
    uploadedAt: toDatetime(doc.data().uploadedAt),
    id: doc.id,
  }));
};

export const saveCard = async ({ id, title, photo, inventory, isAvailable }) => {
  const { currentUser } = getAuth();
  if (!currentUser) {
    throw new ElGuapoError("You must be logged in to save a card");
  }

  const cardId = id || generateID();
  const values = removeUndefined({
    isAvailable,
    inventory,
    title,
    photo,
  });

  if (Object.keys(values).length > 0) {
    if (values.photo && !values.photo.uploadedAt) {
      values.photo = await uploadFile(values.photo, `cards/${cardId}`);
    }

    values.updatedAt = new Date();
    const db = getFirestore();
    await setDoc(doc(db, "CARDS", cardId), values, { merge: true }).catch((e) => {
      throw new ElGuapoError(e.message);
    });

    return { ...values, id: cardId };
  } else {
    return {};
  }
};

export const deleteCard = async (cardId) => {
  if (!cardId) {
    throw new ElGuapoError("Please select what card you would like to delete.");
  }

  const db = getFirestore();
  return deleteDoc(doc(db, "CARDS", cardId)).catch((e) => {
    throw new ElGuapoError(e.message);
  });
};
