// External Imports
import { getFirestore, arrayRemove, arrayUnion, getDoc, setDoc, doc } from "firebase/firestore";

// Internal Imports
import { ElGuapoError } from "errors/ElGuapoError";

export const fetchKeywords = async () => {
  const db = getFirestore();
  const keywordsRef = await getDoc(doc(db, "FILTERS/KEYWORDS")).catch((e) => {
    throw new ElGuapoError(e.message);
  });

  if (keywordsRef.exists()) {
    return keywordsRef.data()?.keywords || [];
  } else {
    return [];
  }
};

export const saveKeyword = async (keyword) => {
  if (keyword) {
    const db = getFirestore();
    await setDoc(
      doc(db, "FILTERS/KEYWORDS"),
      { keywords: arrayUnion(keyword), updatedAt: new Date() },
      { merge: true }
    ).catch((e) => {
      throw new ElGuapoError(e.message);
    });

    return keyword;
  }

  return null;
};

export const deleteKeyword = async (keyword) => {
  if (keyword) {
    const db = getFirestore();
    await setDoc(
      doc(db, "FILTERS/KEYWORDS"),
      { keywords: arrayRemove(keyword), updatedAt: new Date() },
      { merge: true }
    ).catch((e) => {
      throw new ElGuapoError(e.message);
    });

    return keyword;
  }

  return null;
};

export const fetchTags = async () => {
  const db = getFirestore();
  const tagsRef = await getDoc(doc(db, "FILTERS/TAGS")).catch((e) => {
    throw new ElGuapoError(e.message);
  });

  if (tagsRef.exists()) {
    return tagsRef.data()?.tags || [];
  } else {
    return [];
  }
};

export const saveTag = async (tag) => {
  if (tag) {
    const db = getFirestore();
    await setDoc(
      doc(db, "FILTERS/TAGS"),
      { tags: arrayUnion(tag), updatedAt: new Date() },
      { merge: true }
    ).catch((e) => {
      throw new ElGuapoError(e.message);
    });

    return tag;
  }

  return null;
};

export const deleteTag = async (tag) => {
  if (tag) {
    const db = getFirestore();
    await setDoc(
      doc(db, "FILTERS/TAGS"),
      { tags: arrayRemove(tag), updatedAt: new Date() },
      { merge: true }
    ).catch((e) => {
      throw new ElGuapoError(e.message);
    });

    return tag;
  }

  return null;
};
