// External Imports
import produce from "immer";

// Internal Imports
import { types as authTypes } from "state/auth/types";
import { types } from "./types";

export const initialState = {
  keywords: [],
  tags: [],
};

export default function filters(state = initialState, { type, payload }) {
  return produce(state, (draft) => {
    switch (type) {
      case authTypes.LOGOUT: {
        return initialState;
      }

      case types.SET_KEYWORDS: {
        if (Array.isArray(payload)) {
          draft.keywords = payload;
        }
        break;
      }

      case types.SAVE_KEYWORD: {
        if (!draft.keywords.find((kw) => kw.value === payload.value && kw.category === payload.category)) {
          draft.keywords.push(payload);
        }
        break;
      }

      case types.DELETE_KEYWORD: {
        if (payload) {
          draft.keywords = draft.keywords.filter(
            (kw) => !(kw.value === payload.value && kw.category === payload.category)
          );
        }
        break;
      }

      case types.SET_TAGS: {
        if (Array.isArray(payload)) {
          draft.tags = payload;
        }
        break;
      }

      case types.SAVE_TAG: {
        if (!draft.tags.find((t) => t === payload)) {
          draft.tags.push(payload);
        }
        break;
      }

      case types.DELETE_TAG: {
        if (payload) {
          draft.tags = draft.tags.filter((t) => !(t === payload));
        }
        break;
      }

      default: {
        return state;
      }
    }
  });
}
