// External Imports
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FaRegTrashAlt } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";

const Ingredient = ({ index, ingredient, isEditing, updateIngredient, removeIngredient }) => {
  if (ingredient) {
    if (isEditing) {
      return (
        <div style={{ width: "100%", display: "flex", alignItems: "center", margin: "5px 0px" }}>
          <input
            placeholder="Quantity"
            style={{ fontSize: 16 }}
            value={ingredient.quantity}
            onChange={(e) => updateIngredient(index, { quantity: e.target.value })}
          />

          <input
            placeholder="Name"
            style={{ marginLeft: 10, fontSize: 16 }}
            value={ingredient.name}
            onChange={(e) => updateIngredient(index, { name: e.target.value })}
          />

          <input
            placeholder="Link"
            style={{ marginLeft: 10, fontSize: 16 }}
            value={ingredient.link}
            onChange={(e) => updateIngredient(index, { link: e.target.value })}
          />

          <FaRegTrashAlt
            style={{ fontSize: 20, color: "#8B0000", marginLeft: 10 }}
            onClick={() => removeIngredient(index)}
          />
        </div>
      );
    } else if (ingredient.link) {
      return (
        <a href={ingredient.link} target="_blank" rel="noreferrer">
          - {ingredient.quantity} - {ingredient.name} <FiExternalLink />
        </a>
      );
    } else {
      return (
        <div>
          - {ingredient.quantity} - {ingredient.name}
        </div>
      );
    }
  } else {
    return null;
  }
};

const Ingredients = ({ ingredients, setIngredients, isEditing }) => {
  const addIngredient = () => {
    if (isEditing) {
      setIngredients([...ingredients, { name: "", link: "", quantity: "" }]);
    }
  };

  const removeIngredient = (index) => {
    if (isEditing) {
      const ingredientCopy = [...ingredients];
      ingredientCopy.splice(index, 1);
      setIngredients(ingredientCopy);
    }
  };

  const updateIngredient = (index, values) => {
    if (isEditing) {
      const ingredientCopy = [...ingredients];
      ingredientCopy[index] = { ...ingredientCopy[index], ...values };
      setIngredients(ingredientCopy);
    }
  };

  return (
    <div>
      {ingredients.map((i, idx) => (
        <Ingredient
          key={idx}
          index={idx}
          ingredient={i}
          isEditing={isEditing}
          updateIngredient={updateIngredient}
          removeIngredient={removeIngredient}
        />
      ))}

      {isEditing && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <AiOutlinePlusCircle
            style={{ fontSize: 24, margin: "0px 5px", color: "#006400" }}
            onClick={addIngredient}
          />
        </div>
      )}
    </div>
  );
};

export default Ingredients;
