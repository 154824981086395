// Internal Imports
import { setLoading, logError } from "state/status/actions";
import { types } from "./types";
import {
  fetchVendors as fireFetchVendors,
  createVendor as fireCreateVendor,
  updateVendor as fireUpdateVendor,
  deleteVendor as fireDeleteVendor,
} from "services/firestore/vendors";

export const fetchVendors = () => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const vendors = await fireFetchVendors();
      dispatch({
        type: types.SET_VENDORS,
        payload: vendors,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem getting vendors"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const createVendor = (values) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const vendor = await fireCreateVendor(values);
      dispatch({
        type: types.CREATE_VENDOR,
        payload: vendor,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem saving this vendor"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const updateVendor = (vendorId, values) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const updates = await fireUpdateVendor(vendorId, values);
      dispatch({
        type: types.UPDATE_VENDOR,
        payload: { ...updates, id: vendorId },
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem saving this vendor"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const deleteVendor = (vendorId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await fireDeleteVendor(vendorId);
      dispatch({
        type: types.DELETE_VENDOR,
        payload: vendorId,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem deleting this vendor"));
      }
    }

    dispatch(setLoading(false));
  };
};
