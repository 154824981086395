// External Import
import { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

// Internal Imports
import * as actions from "state/cards/actions";

export const useCards = () => {
  // state
  // eslint-disable-next-line
  const unsortedCards = useSelector((state) => state.cards) || [];
  const cards = useMemo(() => [...unsortedCards].sort((a, b) => b.updatedAt - a.updatedAt), [unsortedCards]);
  const dispatch = useDispatch();

  // functions
  const fetchCards = useCallback(() => dispatch(actions.fetchCards()), [dispatch]);
  const saveCard = useCallback((values) => dispatch(actions.saveCard(values)), [dispatch]);
  const deleteCard = useCallback((id) => dispatch(actions.deleteCard(id)), [dispatch]);

  return {
    // state
    cards,

    // functions
    fetchCards,
    saveCard,
    deleteCard,
  };
};
