// External Imports
import { getFirestore, collection, deleteDoc, getDocs, setDoc, doc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Internal Imports
import { removeUndefined, generateID } from "utils";
import { ElGuapoError } from "errors/ElGuapoError";
import { uploadFile } from "services/storage";

export const fetchRecipes = async () => {
  const db = getFirestore();
  const recipeRef = await getDocs(collection(db, "RECIPES")).catch((e) => {
    throw new ElGuapoError(e.message);
  });

  return recipeRef.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
};

export const saveRecipe = async ({
  id,
  title,
  author,
  amount,
  keywords,
  products,
  tags,
  topBlurb,
  ingredients,
  bottomBlurb,
  photo,
  additionalInfo,
}) => {
  const { currentUser } = getAuth();
  if (!currentUser) {
    throw new ElGuapoError("You must be logged in to save a recipe");
  }

  const recipeId = id || generateID();
  const values = removeUndefined({
    title,
    author,
    amount,
    keywords,
    products,
    tags,
    topBlurb,
    ingredients,
    bottomBlurb,
    photo,
    additionalInfo,
  });

  if (Object.keys.length > 0) {
    if (values.photo && !values.photo.uploadedAt) {
      values.photo = await uploadFile(values.photo, `recipes/${recipeId}`);
    }

    values.updatedAt = new Date();
    const db = getFirestore();
    await setDoc(doc(db, "RECIPES", recipeId), values, { merge: true }).catch((e) => {
      throw new ElGuapoError(e.message);
    });

    return { ...values, id: recipeId };
  } else {
    return {};
  }
};

export const deleteRecipe = async (recipeId) => {
  if (!recipeId) {
    throw new ElGuapoError("Please select what recipe you would like to delete.");
  }

  const db = getFirestore();
  return deleteDoc(doc(db, "RECIPES", recipeId)).catch((e) => {
    throw new ElGuapoError(e.message);
  });
};
