// External Imports
import { getFirestore, collection, deleteDoc, getDocs, setDoc, doc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Internal Imports
import { removeUndefined, generateID, toDatetime } from "utils";
import { uploadFile, copyFile } from "services/storage";
import { ElGuapoError } from "errors/ElGuapoError";

export const fetchBanners = async () => {
  const db = getFirestore();
  const bannersRef = await getDocs(collection(db, "CONTENT/BANNERS/MEDIA")).catch((e) => {
    throw new ElGuapoError(e.message);
  });

  return bannersRef.docs.map((doc) => ({
    ...doc.data(),
    uploadedAt: toDatetime(doc.data().uploadedAt),
    id: doc.id,
  }));
};

export const saveBanner = async ({ id, title, photo }) => {
  const { currentUser } = getAuth();
  if (!currentUser) {
    throw new ElGuapoError("You must be logged in to save a banner");
  }

  const bannerId = id || generateID();
  const values = removeUndefined({
    title,
    photo,
  });

  if (Object.keys(values).length > 0) {
    if (values.photo && !values.photo.uploadedAt) {
      values.photo = await uploadFile(values.photo, `banners/${bannerId}`);
    }

    values.updatedAt = new Date();
    const db = getFirestore();
    await setDoc(doc(db, "CONTENT/BANNERS/MEDIA", bannerId), values, { merge: true }).catch((e) => {
      throw new ElGuapoError(e.message);
    });

    return { ...values, id: bannerId };
  } else {
    return {};
  }
};

export const deleteBanner = async (bannerId) => {
  if (!bannerId) {
    throw new ElGuapoError("Please select what banner you would like to delete.");
  }

  const db = getFirestore();
  return deleteDoc(doc(db, "CONTENT/BANNERS/MEDIA", bannerId)).catch((e) => {
    throw new ElGuapoError(e.message);
  });
};

export const setCurrentBanner = async (banner) => {
  const { currentUser } = getAuth();
  if (!currentUser) {
    throw new ElGuapoError("You must be logged in to save a banner");
  }

  if (!banner || !banner.id || !banner.photo?.filePath) {
    throw new ElGuapoError("Invalid banner");
  }

  try {
    await copyFile(banner.photo.filePath, "banners/current", true);
  } catch (e) {
    console.error(e);
    throw new ElGuapoError(e.message);
  }

  const updates = {
    updatedAt: new Date(),
    current: banner.id,
  };

  const db = getFirestore();
  await setDoc(doc(db, "CONTENT", "BANNERS"), updates, { merge: true }).catch((e) => {
    throw new ElGuapoError(e.message);
  });

  return banner.id;
};
