// External Imports
import { getFirestore, collection, doc } from "firebase/firestore";

export const generateID = () => {
  const db = getFirestore();
  return doc(collection(db, "tmp")).id;
};

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const removeUndefined = (obj = {}) => {
  const definedObj = {};
  Object.entries(obj).forEach(([k, v]) => {
    if (typeof v !== "undefined") {
      definedObj[k] = v;
    }
  });

  return definedObj;
};

export const toDatetime = (date, defaultValue = null) => {
  if (Number.isInteger(date?.seconds)) {
    // firestore timestamp
    return new Date(date?.seconds * 1000);
  } else if (typeof date === String) {
    // datetime string
    return Date.parse(date);
  } else if (date instanceof Date && !isNaN(date)) {
    // date object
    return date;
  } else {
    // invalid -> return default
    return defaultValue || new Date();
  }
};
