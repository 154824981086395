// External Imports
import { useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";

// Internal Imports
import { useDistributors } from "hooks/useDistributors";
import LocationModal from "components/LocationModal";
import LocationList from "components/LocationList";
import DeleteModal from "components/DeleteModal";
import GoogleMap from "components/Map";
// import { fakeData } from "./fakeData";

const Distributors = () => {
  const { distributors, createDistributor, updateDistributor, deleteDistributor } = useDistributors();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const selected = distributors[selectedIndex];

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);

  const onCreate = (values) => {
    createDistributor(values);
    setShowNewModal(false);
  };

  const onUpdate = (values) => {
    if (selected && selected.id) {
      updateDistributor(selected.id, values);
    }
    setShowEditModal(false);
  };

  const onDelete = () => {
    if (selected && selected.id) {
      deleteDistributor(selected.id);
    }
    // close all modals
    setShowDeleteModal(false);
    setShowEditModal(false);
    setShowNewModal(false);
  };

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <LocationModal
        show={showNewModal}
        hide={() => setShowNewModal(false)}
        title="New Distributor"
        location={selected}
        onSave={onCreate}
        onDelete={() => setShowNewModal(false)}
      />

      <LocationModal
        show={showEditModal}
        hide={() => setShowEditModal(false)}
        title="Edit Distributor"
        location={selected}
        onSave={onUpdate}
        onDelete={() => setShowDeleteModal(true)}
      />

      <DeleteModal
        show={showDeleteModal}
        hide={() => setShowDeleteModal(false)}
        title="Delete Distributor"
        onDelete={onDelete}
      />

      <div style={{ width: "20%", padding: 20, paddingRight: 0 }}>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            color: "white",
            marginBottom: 10,
            fontSize: 20,
          }}
          onClick={() => setShowNewModal(true)}
        >
          <AiOutlinePlusCircle style={{ marginRight: 10 }} /> New Distributor
        </div>

        <LocationList
          locations={distributors}
          selectedIndex={selectedIndex}
          onClick={(idx) => setSelectedIndex(idx)}
          onEdit={() => setShowEditModal(true)}
          onDelete={() => setShowDeleteModal(true)}
        />
      </div>

      <div style={{ width: "80%", padding: 20 }}>
        <GoogleMap
          locations={distributors}
          style={{ flexGrow: "1", height: "100%", borderRadius: 20 }}
          onClick={(idx) => setSelectedIndex(idx)}
        />
      </div>
    </div>
  );
};

export default Distributors;
