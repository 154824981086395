// External Imports
import { AiOutlineDelete } from "react-icons/ai";

// Internal Imports
import { capitalize } from "utils";

const Tag = ({ tag, onDelete }) => {
  if (tag) {
    return (
      <div
        style={{
          justifyContent: "space-between",
          background: "white",
          alignItems: "center",
          padding: "5px 7px 5px 14px",
          margin: "10px 10px",
          display: "flex",
          borderRadius: 15,
        }}
      >
        <div style={{ fontSize: 18, color: "black" }}>{capitalize(tag || "")}</div>
        <AiOutlineDelete
          style={{ fontSize: 24, top: 10, right: 10, marginRight: 5, color: "#8B0000" }}
          onClick={() => onDelete(tag)}
        />
      </div>
    );
  } else {
    return null;
  }
};

const columnWidth = "20%";
const TagPanel = ({ tags, handleDelete }) => {
  return (
    <div
      style={{
        textAlign: "center",
        flexFlow: "column",
        overflow: "auto",
        display: "flex",
        color: "white",
        marginTop: 30,
        fontSize: 24,
        flexGrow: 1,
      }}
    >
      All Tags
      <div
        style={{
          justifyContent: "flex-start",
          flexWrap: "wrap",
          display: "flex",
        }}
      >
        {tags.map((tag) => (
          <div
            key={tag}
            style={{
              width: columnWidth,
            }}
          >
            <Tag tag={tag} onDelete={handleDelete} width={columnWidth} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TagPanel;
