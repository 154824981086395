// External Imports
import { useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";

// Internal Imports
import DeleteModal from "components/DeleteModal";
import PostList from "components/PostList";
import RecipePreview from "./RecipePreview";
import { useRecipes } from "hooks/useRecipes";
import { generateID } from "utils";

const Recipes = () => {
  const { recipes, saveRecipe, deleteRecipe } = useRecipes();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleSave = (values) => {
    if (values && values.id) {
      saveRecipe(values);
      setSelected(values);
    }
  };

  const handleDelete = () => {
    if (selected && selected.id) {
      deleteRecipe(selected.id);
    }
    setShowDeleteModal(false);
    setIsEditing(false);
    setSelected(null);
  };

  const handleNew = () => {
    setIsEditing(true);
    setSelected({ id: generateID() });
  };

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <DeleteModal
        show={showDeleteModal}
        hide={() => setShowDeleteModal(false)}
        title="Delete Recipe"
        onDelete={handleDelete}
      />

      <div style={{ width: "30%", padding: 20, paddingRight: 0, display: "flex", flexFlow: "column" }}>
        <div
          style={{
            boxShadow: "2px 2px 2px grey",
            justifyContent: "center",
            alignItems: "center",
            padding: "15px 15px",
            background: "white",
            display: "flex",
            borderRadius: 15,
            marginBottom: 15,
            fontSize: 20,
          }}
          onClick={handleNew}
        >
          <AiOutlinePlusCircle style={{ marginRight: 10 }} /> New Recipe
        </div>

        <div style={{ flexGrow: 1, overflowY: "auto" }}>
          <PostList
            posts={recipes}
            selected={selected}
            onClick={(post) => setSelected(post)}
            onEdit={() => setIsEditing(true)}
            onDelete={() => setShowDeleteModal(true)}
          />
        </div>
      </div>

      <div style={{ width: "70%", padding: 20 }}>
        <RecipePreview
          recipe={selected}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          onSave={handleSave}
          onDelete={() => setShowDeleteModal(true)}
        />
      </div>
    </div>
  );
};

export default Recipes;
