// Internal Imports
import { setLoading, logError } from "state/status/actions";
import { types } from "./types";
import {
  fetchBlogs as fireFetchBlogs,
  deleteBlog as fireDeleteBlog,
  saveBlog as fireSaveBlog,
} from "services/firestore/blogs";

export const fetchBlogs = () => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const blogs = await fireFetchBlogs();
      dispatch({
        type: types.SET_BLOGS,
        payload: blogs,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem getting blogs"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const saveBlog = (values) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const updates = await fireSaveBlog(values);
      dispatch({
        type: types.SAVE_BLOG,
        payload: updates,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem saving this blog"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const deleteBlog = (blogId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await fireDeleteBlog(blogId);
      dispatch({
        type: types.DELETE_BLOG,
        payload: blogId,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem deleting this blog"));
      }
    }

    dispatch(setLoading(false));
  };
};
