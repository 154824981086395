// External Imports
import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

// Internal Imports
import { useDistributors } from "hooks/useDistributors";
import { useAdminUsers } from "hooks/useAdminUsers";
import { useProducts } from "hooks/useProducts";
import { useVendors } from "hooks/useVendors";
import { useRecipes } from "hooks/useRecipes";
import { useContent } from "hooks/useContent";
import { useFilters } from "hooks/useFilters";
import { useBlogs } from "hooks/useBlogs";
import { useCards } from "hooks/useCards";
import { useAuth } from "hooks/useAuth";
import Distributors from "./Distributors";
import Keywords from "./Keywords";
import Vendors from "./Vendors";
import Content from "./Content";
import Recipes from "./Recipes";
import Users from "./Users";
import Blogs from "./Blogs";
import Cards from "./Cards";
import Home from "./Home";
import Nav from "./Nav";

const PanelSwitch = ({ tab }) => {
  switch (tab) {
    case "home":
      return <Home />;

    case "content":
      return <Content />;

    case "blogs":
      return <Blogs />;

    case "recipes":
      return <Recipes />;

    case "keywords":
      return <Keywords />;

    case "distributors":
      return <Distributors />;

    case "vendors":
      return <Vendors />;

    case "cards":
      return <Cards />;

    case "users":
      return <Users />;

    default:
      return <Home />;
  }
};

const Dashboard = () => {
  const { isAuthenticated, permissions } = useAuth();
  const { fetchKeywords, fetchTags } = useFilters();
  const { fetchDistributors } = useDistributors();
  const { fetchAdminUsers } = useAdminUsers();
  const { fetchProducts } = useProducts();
  const { fetchVendors } = useVendors();
  const { fetchRecipes } = useRecipes();
  const { fetchBanners } = useContent();
  const { fetchBlogs } = useBlogs();
  const { fetchCards } = useCards();
  const { tab } = useParams();

  useEffect(() => {
    if (isAuthenticated) {
      // page specifc data depends on permissions
      if (permissions.distributors || permissions.superuser) fetchDistributors();
      if (permissions.content || permissions.superuser) fetchBanners();
      if (permissions.vendors || permissions.superuser) fetchVendors();
      if (permissions.recipes || permissions.superuser) fetchRecipes();
      if (permissions.blogs || permissions.superuser) fetchBlogs();
      if (permissions.cards || permissions.superuser) fetchCards();
      if (permissions.superuser) fetchAdminUsers();

      // used on multiple pages
      fetchProducts();
      fetchKeywords();
      fetchTags();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <div
      style={{
        height: "100vh",
        background: "grey",
      }}
    >
      {!isAuthenticated && <Navigate to="/" replace={true} />}
      <div style={{ height: "100%", display: "flex" }}>
        <Nav currentTab={tab} />
        <PanelSwitch tab={tab} />
      </div>
    </div>
  );
};

export default Dashboard;
