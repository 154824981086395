// External Imports
import { useState } from "react";

// Internal Imports
import Modal from "components/Modal";

const TagModal = ({ show, hide, onSave, onDelete }) => {
  const [tag, setTag] = useState("");

  const disabled = !tag;

  const handleSave = () => {
    if (!disabled) {
      onSave(tag.toLowerCase());
      setTag("");
    }
  };

  return (
    <Modal show={show} hide={hide} title={"New Tag"} style={{ width: 400 }}>
      <div style={{ margin: "10px 0px", display: "flex", flexDirection: "column", fontSize: 18 }}>
        <input
          placeholder="Tag (i.e. Lime)"
          style={{ width: "auto", fontSize: 16, margin: 10 }}
          value={tag}
          onChange={(e) => setTag(e.target.value)}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-around", marginTop: 20 }}>
        <button style={{ padding: "5px 10px", fontSize: 16 }} onClick={() => onDelete()}>
          Delete
        </button>

        <button
          style={{ padding: "5px 10px", fontSize: 16, opacity: disabled ? 0.5 : 1.0 }}
          disabled={disabled}
          onClick={() => handleSave()}
        >
          Save
        </button>
      </div>
    </Modal>
  );
};

export default TagModal;
