// External Imports
import produce from "immer";

// Internal Imports
import { types } from "./types";

export const initialState = {
  isAuthenticated: false,
  permissions: {},
  user: null,
};

export default function auth(state = initialState, { type, payload }) {
  return produce(state, (draft) => {
    switch (type) {
      case types.LOGOUT: {
        return initialState;
      }

      case types.LOGIN: {
        draft.isAuthenticated = true;
        draft.permissions = payload.permissions;
        draft.user = payload.user;
        break;
      }

      default: {
        return state;
      }
    }
  });
}
