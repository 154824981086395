// Internal Imports
import { setLoading, logError } from "state/status/actions";
import { types } from "./types";
import {
  fetchRecipes as fireFetchRecipes,
  deleteRecipe as fireDeleteRecipe,
  saveRecipe as fireSaveRecipe,
} from "services/firestore/recipes";

export const fetchRecipes = () => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const recipes = await fireFetchRecipes();
      dispatch({
        type: types.SET_RECIPES,
        payload: recipes,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem getting recipes"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const saveRecipe = (values) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const updates = await fireSaveRecipe(values);
      dispatch({
        type: types.SAVE_RECIPE,
        payload: updates,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem saving this recipe"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const deleteRecipe = (recipeId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await fireDeleteRecipe(recipeId);
      dispatch({
        type: types.DELETE_RECIPE,
        payload: recipeId,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem deleting this recipe"));
      }
    }

    dispatch(setLoading(false));
  };
};
