// External Imports
import produce from "immer";

// Internal Imports
import { types as authTypes } from "state/auth/types";
import { types } from "./types";

export const initialState = [];

export default function recipes(state = initialState, { type, payload }) {
  return produce(state, (draft) => {
    switch (type) {
      case authTypes.LOGOUT: {
        return initialState;
      }

      case types.SET_RECIPES: {
        if (Array.isArray(payload)) {
          return payload;
        }
        break;
      }

      case types.SAVE_RECIPE: {
        let index = draft.findIndex((b) => b.id === payload.id);
        if (index > -1) {
          draft[index] = { ...draft[index], ...payload };
        } else {
          draft.push(payload);
        }
        break;
      }

      case types.DELETE_RECIPE: {
        return draft.filter((b) => b.id !== payload);
      }

      default: {
        return state;
      }
    }
  });
}
