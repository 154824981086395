// Internal Imports
import { useAuth } from "hooks/useAuth";

const Home = () => {
  const { user } = useAuth();

  return (
    <div
      style={{
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      <img src="/imgs/guapoLogo.png" alt="error" style={{ width: 500, marginTop: 0, marginBottom: 0 }} />
      <p style={{ fontSize: 40, color: "white" }}>Welcome back {user?.name || ""}</p>
    </div>
  );
};

export default Home;
