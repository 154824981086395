const options = [
  { value: "spirit", label: "Spirit" },
  { value: "characteristic", label: "Characteristic" },
  { value: "occasion", label: "Occasion" },
  { value: "food", label: "Food" },
  { value: "topics", label: "Topics" },
];

const KeywordCategorySelect = ({ value, onChange, style = {} }) => {
  return (
    <select style={style} value={value} onChange={(e) => onChange(e.target.value)}>
      <option>Select a keyword category</option>
      {options.map((cat) => (
        <option key={cat.value} value={cat.value}>
          {cat.label}
        </option>
      ))}
    </select>
  );
};

export default KeywordCategorySelect;
