// External Imports
import { useMemo } from "react";
import Select from "react-select";

// Internal Imports
import { useFilters } from "hooks/useFilters";
import { capitalize } from "utils";

const KeywordSelect = ({ value, onChange, style = {} }) => {
  const { keywords } = useFilters();
  const kwOptions = useMemo(
    () => keywords.map((kw) => ({ value: kw.value, label: capitalize(kw.value) })),
    [keywords]
  );
  const values = useMemo(
    () => value.map((kw) => kwOptions.find((kwo) => kwo.value === kw)),
    [kwOptions, value]
  );

  const handleChange = (values) => {
    onChange(values.map((kw) => kw.value));
  };

  return (
    <div style={style}>
      <Select
        options={kwOptions}
        value={values}
        onChange={handleChange}
        isClearable={true}
        isMulti={true}
        isSearchable={true}
        placeholder="Keywords"
        noOptionsMessage={() => "No matching keywords"}
      />
    </div>
  );
};

export default KeywordSelect;
