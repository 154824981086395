// External Imports
import { useState, useEffect } from "react";
import { AiOutlineSearch } from "react-icons/ai";

// Internal Imports
import { IndexCell, HeaderCell, RowItem } from "./components";

const theme = {
  headerBackground: "white",
  headerColor: "",
  indexBackground: "white",
  indexColor: "",
  cellBackground: "",
  cellColor: "#F0F0E8",
  selectedBackground: "white",
  selectedColor: "",
  searchBackground: "",
  searchColor: "#F0F0E8",
  borderColor: "#D3D3D3",
};

const DataTable = ({ data, schema, selectedRecord, onRecordSelect, cornerBtn }) => {
  const [searchedData, setSearchedData] = useState(data);
  const [searchPhrase, setSearchPhrase] = useState("");

  const [sortDirection, setSortDirection] = useState("desc");
  const [sortedData, setSortedData] = useState(data);
  const [sortValue, setSortValue] = useState(null);

  useEffect(() => {
    if (sortValue) {
      const sortFn = schema.find((s) => s.value === sortValue)?.sortFns?.[sortDirection];
      if (sortFn) {
        setSortedData([...data].sort(sortFn));
      } else {
        setSortedData(data);
      }
    } else {
      setSortedData(data);
    }
  }, [data, schema, sortValue, sortDirection]);

  useEffect(() => {
    if (searchPhrase.length > 2) {
      setSearchedData(sortedData.filter((u) => u.email.includes(searchPhrase)));
    } else {
      setSearchedData(sortedData);
    }
  }, [sortedData, searchPhrase]);

  return (
    <div style={{ height: "100%", width: "100%", display: "flex", flexFlow: "column" }}>
      <div
        style={{
          background: theme.searchBackground,
          alignItems: "center",
          display: "flex",
          paddingBottom: 10,
        }}
      >
        <div
          style={{
            border: `1px solid ${theme.searchColor}`,
            padding: "5px 10px",
            color: theme.searchColor,
            width: "100%",
            borderRadius: 15,
          }}
          placeholder="Search"
          onInput={(e) => setSearchPhrase(e.currentTarget.textContent)}
          value={searchPhrase}
          contentEditable={true}
        />
        <AiOutlineSearch
          style={{
            color: theme.searchColor,
            marginLeft: 10,
            flexShrink: 0,
            marginTop: 3,
            fontSize: 24,
          }}
        />
      </div>

      <div style={{ display: "flex", flexGrow: 1 }}>
        <div style={{ height: "100%", width: "100%" }}>
          {/* Table Headers */}
          <div style={{ display: "flex" }}>
            <IndexCell
              background={theme.indexBackground}
              color={theme.indexColor}
              borderColor={theme.borderColor}
              btn={cornerBtn}
            />
            <div style={{ display: "flex", width: "100%" }}>
              {schema.map((c) => (
                <HeaderCell
                  key={c.label}
                  label={c.label}
                  value={c.value}
                  sortValue={sortValue}
                  setSortValue={setSortValue}
                  sortDirection={sortDirection}
                  setSortDirection={setSortDirection}
                  background={theme.headerBackground}
                  color={theme.headerColor}
                  borderColor={theme.borderColor}
                />
              ))}
            </div>
          </div>

          {/* Table Rows */}
          <div style={{ height: "90%", width: "100%", overflowY: "auto" }}>
            {searchedData.map((r, i) => {
              if (r) {
                return (
                  <RowItem
                    key={r.id || i}
                    index={i}
                    record={r}
                    schema={schema}
                    selectedRecord={selectedRecord}
                    setSelectedRecord={onRecordSelect}
                    indexBackground={theme.indexBackground}
                    indexColor={theme.indexColor}
                    cellBackground={theme.cellBackground}
                    cellColor={theme.cellColor}
                    selectedBackground={theme.selectedBackground}
                    selectedColor={theme.selectedColor}
                    borderColor={theme.borderColor}
                  />
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataTable;
