// External Imports
import produce from "immer";

// Internal Imports
import { types as authTypes } from "state/auth/types";
import { types } from "./types";

export const initialState = [];

export default function products(state = initialState, { type, payload }) {
  return produce(state, (draft) => {
    switch (type) {
      case authTypes.LOGOUT: {
        return initialState;
      }

      case types.SET_PRODUCTS: {
        if (Array.isArray(payload)) {
          return payload;
        }
        break;
      }

      default: {
        return state;
      }
    }
  });
}
