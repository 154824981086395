// Internal Imports
import { setLoading, logError } from "state/status/actions";
import { types } from "./types";
import {
  fetchDistributors as fireFetchDistributors,
  createDistributor as fireCreateDistributor,
  updateDistributor as fireUpdateDistributor,
  deleteDistributor as fireDeleteDistributor,
} from "services/firestore/distributors";

export const fetchDistributors = () => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const distributors = await fireFetchDistributors();
      dispatch({
        type: types.SET_DISTRIBUTORS,
        payload: distributors,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem getting distributors"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const createDistributor = (values) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const distributor = await fireCreateDistributor(values);
      dispatch({
        type: types.CREATE_DISTRIBUTOR,
        payload: distributor,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem saving this distributor"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const updateDistributor = (distributorId, values) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const updates = await fireUpdateDistributor(distributorId, values);
      dispatch({
        type: types.UPDATE_DISTRIBUTOR,
        payload: { ...updates, id: distributorId },
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem saving this distributor"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const deleteDistributor = (distributorId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await fireDeleteDistributor(distributorId);
      dispatch({
        type: types.DELETE_DISTRIBUTOR,
        payload: distributorId,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem deleting this distributor"));
      }
    }

    dispatch(setLoading(false));
  };
};
