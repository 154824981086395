// External Import
import { useSelector, useDispatch } from "react-redux";

// Internal Imports
import * as actions from "state/products/actions";

export const useProducts = () => {
  // state
  const products = useSelector((state) => state.products) || [];
  const dispatch = useDispatch();

  // functions
  const fetchProducts = () => dispatch(actions.fetchProducts());

  return {
    // state
    products,

    // functions
    fetchProducts,
  };
};
