// External Imports
import { useState, useEffect } from "react";
import { MdOutlinePhotoSizeSelectActual } from "react-icons/md";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye, AiOutlineSave } from "react-icons/ai";

// Internal Imports
import UploadButtonWrapper from "components/UploadButtonWrapper";
import KeywordSelect from "components/forms/KeywordSelect";
import ProductSelect from "components/forms/ProductSelect";
import TagSelect from "components/forms/TagSelect";
import Ingredients from "./Ingredients";

const RecipePreview = ({ recipe, isEditing, setIsEditing, onSave, onDelete }) => {
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [amount, setAmount] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [products, setProducts] = useState([]);
  const [tags, setTags] = useState([]);
  const [topBlurb, setTopBlurb] = useState("");
  const [bottomBlurb, setBottomBlurb] = useState("");
  const [photo, setPhoto] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [ingredients, setIngredients] = useState([]);

  useEffect(() => {
    if (recipe) {
      setTitle(recipe.title || "");
      setAuthor(recipe.author || "");
      setAmount(recipe.amount || "");
      setKeywords(recipe.keywords || []);
      setProducts(recipe.products || []);
      setTags(recipe.tags || []);
      setTopBlurb(recipe.topBlurb || "");
      setBottomBlurb(recipe.bottomBlurb || "");
      setPhoto(recipe.photo || null);
      setAdditionalInfo(recipe.additionalInfo || "");
      setIngredients(recipe.ingredients || []);
    } else {
      reset();
    }
  }, [recipe]);

  const reset = () => {
    setTitle("");
    setAuthor("");
    setAmount("");
    setKeywords([]);
    setProducts([]);
    setTags([]);
    setTopBlurb("");
    setIngredients([]);
    setBottomBlurb("");
    setPhoto(null);
    setAdditionalInfo("");
  };

  const handleSave = () => {
    onSave({
      id: recipe.id || null,
      title,
      author,
      amount,
      keywords,
      products,
      tags,
      topBlurb,
      ingredients,
      bottomBlurb,
      photo,
      additionalInfo,
    });
  };

  return (
    <div
      style={{
        boxSizing: "border-box",
        padding: "30px 50px",
        background: "white",
        height: "100%",
        width: "100%",
        borderRadius: 20,
      }}
    >
      {recipe || isEditing ? (
        <div style={{ display: "flex", flexFlow: "column", height: "100%" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <AiOutlineDelete style={{ fontSize: 24, color: "#8B0000", marginRight: 5 }} onClick={onDelete} />
            <AiOutlineSave style={{ fontSize: 24, marginRight: 5 }} onClick={handleSave} />
            {isEditing ? (
              <AiOutlineEye style={{ fontSize: 24 }} onClick={() => setIsEditing(false)} />
            ) : (
              <AiOutlineEdit style={{ fontSize: 24 }} onClick={() => setIsEditing(true)} />
            )}
          </div>

          <div
            style={{
              flexDirection: "column",
              alignItems: "center",
              padding: "0px 5px",
              overflowY: "auto",
              display: "flex",
              flexGrow: 1,
            }}
          >
            {isEditing ? (
              <input
                style={{ fontSize: 40 }}
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            ) : (
              <div style={{ fontSize: 40 }}>{title}</div>
            )}

            {isEditing ? (
              <input
                style={{ fontSize: 18, marginTop: 5 }}
                placeholder="Author"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
              />
            ) : (
              <div style={{ fontSize: 18 }}>By: {author}</div>
            )}

            <UploadButtonWrapper
              disabled={!isEditing}
              onChange={(files) => files.length > 0 && setPhoto(files[0])}
              style={{
                justifyContent: "center",
                alignItems: "center",
                margin: "30px 0px",
                display: "flex",
                height: 250,
              }}
            >
              {photo && photo.downloadURL ? (
                <img
                  src={photo.downloadURL}
                  alt="recipe"
                  style={{ height: "100%", objectFit: "cover", borderRadius: 14 }}
                />
              ) : (
                <MdOutlinePhotoSizeSelectActual style={{ fontSize: 200 }} />
              )}
            </UploadButtonWrapper>

            {isEditing ? (
              <input
                style={{ fontSize: 16, width: "100%", marginBottom: 30 }}
                placeholder="Top Content"
                value={topBlurb}
                onChange={(e) => setTopBlurb(e.target.value)}
              />
            ) : (
              <div style={{ fontSize: 16, width: "100%", marginBottom: 30 }}>
                {topBlurb ? topBlurb : "No top conent"}
              </div>
            )}

            {isEditing ? (
              <input
                style={{ fontSize: 16, width: "100%", marginBottom: 30 }}
                placeholder="Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            ) : (
              <div style={{ fontSize: 16, width: "100%", marginBottom: 30 }}>
                {amount ? amount : "No amount"}
              </div>
            )}

            <div
              style={{
                margin: "20px 0px",
                display: "flex",
                fontSize: 18,
                flexDirection: ingredients.length > 0 && "column",
                alignItems: ingredients.length === 0 && "center",
              }}
            >
              Ingredients:
              <Ingredients ingredients={ingredients} setIngredients={setIngredients} isEditing={isEditing} />
            </div>

            {isEditing ? (
              <input
                style={{ fontSize: 16, width: "100%", marginBottom: 30 }}
                placeholder="Bottom Content"
                value={bottomBlurb}
                onChange={(e) => setBottomBlurb(e.target.value)}
              />
            ) : (
              <div style={{ fontSize: 16, width: "100%", marginBottom: 30 }}>
                {bottomBlurb ? bottomBlurb : "No bottom conent"}
              </div>
            )}

            {isEditing ? (
              <input
                style={{ fontSize: 16, width: "100%", marginBottom: 30 }}
                placeholder="Additiona lInfo"
                value={additionalInfo}
                onChange={(e) => setAdditionalInfo(e.target.value)}
              />
            ) : (
              <div style={{ fontSize: 16, width: "100%", marginBottom: 30 }}>
                {Array.isArray(additionalInfo) && additionalInfo.length > 0
                  ? additionalInfo
                  : "No recipe additional info"}
              </div>
            )}

            {isEditing ? (
              <KeywordSelect
                style={{ fontSize: 16, width: "100%", marginBottom: 30 }}
                value={keywords}
                onChange={(kws) => setKeywords(kws)}
              />
            ) : (
              <div style={{ fontSize: 16, width: "100%", marginBottom: 30 }}>
                {Array.isArray(keywords) && keywords.length > 0 ? keywords : "No recipe keywords"}
              </div>
            )}

            {isEditing ? (
              <ProductSelect
                style={{ fontSize: 16, width: "100%", marginBottom: 30 }}
                value={products}
                onChange={(ps) => setProducts(ps)}
              />
            ) : (
              <div style={{ fontSize: 16, width: "100%", marginBottom: 30 }}>
                {Array.isArray(products) && products.length > 0 ? keywords : "No recipe products"}
              </div>
            )}

            {isEditing ? (
              <TagSelect
                style={{ fontSize: 16, width: "100%", marginBottom: 30 }}
                value={tags}
                onChange={(ts) => setTags(ts)}
              />
            ) : (
              <div style={{ fontSize: 16, width: "100%", marginBottom: 30 }}>
                {Array.isArray(tags) && tags.length > 0 ? keywords : "No recipe tags"}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 30,
          }}
        >
          Select a recipe to preview
        </div>
      )}
    </div>
  );
};

export default RecipePreview;
