// External Imports
import { combineReducers } from "redux";

// module reducers
import distributors, { initialState as distributorsInitialState } from "./distributors/reducer";
import adminUsers, { initialState as adminUsersInitialState } from "./adminUsers/reducer";
import products, { initialState as productsInitialState } from "./products/reducer";
import vendors, { initialState as vendorsInitialState } from "./vendors/reducer";
import recipes, { initialState as recipesInitialState } from "./recipes/reducer";
import content, { initialState as contentInitialState } from "./content/reducer";
import filters, { initialState as filtersInitialState } from "./filters/reducer";
import status, { initialState as statusInitialState } from "./status/reducer";
import blogs, { initialState as blogsInitialState } from "./blogs/reducer";
import cards, { initialState as cardsInitialState } from "./cards/reducer";
import auth, { initialState as authInitialState } from "./auth/reducer";

export const rootReducer = combineReducers({
  distributors,
  adminUsers,
  products,
  vendors,
  recipes,
  content,
  filters,
  status,
  blogs,
  cards,
  auth,
});

export const initialState = {
  distributors: distributorsInitialState,
  adminUsers: adminUsersInitialState,
  products: productsInitialState,
  vendors: vendorsInitialState,
  recipes: recipesInitialState,
  content: contentInitialState,
  filters: filtersInitialState,
  status: statusInitialState,
  blogs: blogsInitialState,
  cards: cardsInitialState,
  auth: authInitialState,
};
