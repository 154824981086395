// External Import
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

// Internal Imports
import * as actions from "state/distributors/actions";

export const useDistributors = () => {
  // state
  const distributors = useSelector((state) => state.distributors);
  const dispatch = useDispatch();

  // functions
  const fetchDistributors = useCallback(() => dispatch(actions.fetchDistributors()), [dispatch]);
  const createDistributor = useCallback((values) => dispatch(actions.createDistributor(values)), [dispatch]);
  const updateDistributor = useCallback(
    (id, values) => dispatch(actions.updateDistributor(id, values)),
    [dispatch]
  );
  const deleteDistributor = useCallback((id) => dispatch(actions.deleteDistributor(id)), [dispatch]);

  return {
    // state
    distributors,

    // functions
    fetchDistributors,
    createDistributor,
    updateDistributor,
    deleteDistributor,
  };
};
