// External Imports
import { useState } from "react";

// Internal Imports
import { useAuth } from "hooks/useAuth";

const inputStyle = {
  margin: "10px 0px",
  padding: 10,
  fontSize: 18,
  borderRadius: 5,
};

const Login = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const { login } = useAuth();

  document.body.style.overflow = "hidden";
  const buttonDisabled = !email || !password;

  const handleLogin = (e) => {
    e.preventDefault();
    login(email, password);
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ width: 400, display: "flex", flexDirection: "column" }}>
        <img src="/imgs/guapoLogo.png" alt="el-guapo-logo" style={{ width: "100%", marginBottom: 30 }} />
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="example@elguapo.com"
          style={inputStyle}
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          style={inputStyle}
        />
        <button onClick={(e) => handleLogin(e)} style={inputStyle} disabled={buttonDisabled}>
          Login
        </button>
      </div>
    </div>
  );
};

export default Login;
