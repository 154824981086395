// External Imports
import { AiOutlineCloseCircle } from "react-icons/ai";

// Internal Imports
import ScreenBlur from "./ScreenBlur";

const Modal = ({ show, hide, title, style = {}, children }) => {
  if (show) {
    return (
      <ScreenBlur background="black" opacity={0.5} onClick={hide}>
        <div
          onClick={(e) => e.stopPropagation()}
          style={{
            position: "absolute",
            background: "white",
            display: "table",
            margin: "auto",
            borderRadius: 20,
            padding: 30,
            zIndex: 10,
            bottom: 0,
            right: 0,
            left: 0,
            top: 0,
          }}
        >
          <div
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
              marginBottom: 10,
              fontSize: 24,
            }}
          >
            {title}
            <AiOutlineCloseCircle style={{ fontSize: 24 }} onClick={hide} />
          </div>
          <div style={{ marginTop: 10, ...style }}>{children}</div>
        </div>
      </ScreenBlur>
    );
  } else {
    return null;
  }
};

export default Modal;
