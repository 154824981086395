// External Import
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

// Internal Imports
import * as actions from "state/vendors/actions";

export const useVendors = () => {
  // state
  const vendors = useSelector((state) => state.vendors);
  const dispatch = useDispatch();

  // functions
  const fetchVendors = useCallback(() => dispatch(actions.fetchVendors()), [dispatch]);
  const createVendor = useCallback((values) => dispatch(actions.createVendor(values)), [dispatch]);
  const updateVendor = useCallback((id, values) => dispatch(actions.updateVendor(id, values)), [dispatch]);
  const deleteVendor = useCallback((id) => dispatch(actions.deleteVendor(id)), [dispatch]);

  return {
    // state
    vendors,

    // functions
    fetchVendors,
    createVendor,
    updateVendor,
    deleteVendor,
  };
};
