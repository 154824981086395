// External Import
import { useEffect } from "react";
import { getAuth, onAuthStateChanged, getIdTokenResult } from "firebase/auth";

// Internal Imports
import { useAuth } from "./useAuth";

const getPermissions = (claims) => {
  const permissions = {};
  if (claims.superuser) {
    permissions.superuser = true;
    return permissions;
  }

  if (claims.content) permissions.content = true;
  if (claims.blogs) permissions.blogs = true;
  if (claims.recipes) permissions.recipes = true;
  if (claims.distributors) permissions.distributors = true;
  if (claims.vendors) permissions.vendors = true;
  return permissions;
};

export const useSessionListener = () => {
  const { shadowLogin, shadowLogout } = useAuth();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (session) => {
      if (session) {
        const { claims } = await getIdTokenResult(session);
        const permissions = getPermissions(claims);
        const user = {
          name: session.displayName,
          email: session.email,
          joinedAt: session.metadata.creationTime,
          lastLogin: session.metadata.lastSignInTime,
        };

        shadowLogin(user, permissions);
      } else {
        shadowLogout(session);
      }
    });

    return unsubscribe;
    // eslint-disable-next-line
  }, []);
};
