// External Imports
import { useMemo } from "react";
import Select from "react-select";

// Internal Imports
import { useProducts } from "hooks/useProducts";

const ProductSelect = ({ value, onChange, style = {} }) => {
  const { products } = useProducts();
  const pOptions = useMemo(() => products.map((p) => ({ value: p.id, label: p.title })), [products]);
  const values = useMemo(() => value.map((p) => pOptions.find((po) => po.value === p)), [pOptions, value]);

  const handleChange = (values) => {
    onChange(values.map((p) => p.value));
  };

  return (
    <div style={style}>
      <Select
        options={pOptions}
        value={values}
        onChange={handleChange}
        isMulti={true}
        isSearchable={true}
        placeholder="Products"
        noOptionsMessage={() => "No matching products"}
      />
    </div>
  );
};

export default ProductSelect;
