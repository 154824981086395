// External Import
import { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

// Internal Imports
import * as actions from "state/content/actions";

export const useContent = () => {
  // state
  const currentBanner = useSelector((state) => state.content.currentBanner) || [];
  // eslint-disable-next-line
  const unsortedBanners = useSelector((state) => state.content.banners) || [];
  const banners = useMemo(
    () => [...unsortedBanners].sort((a, b) => b.updatedAt - a.updatedAt),
    [unsortedBanners]
  );
  const dispatch = useDispatch();

  // functions
  const setCurrentBanner = useCallback((banner) => dispatch(actions.setCurrentBanner(banner)), [dispatch]);
  const fetchBanners = useCallback(() => dispatch(actions.fetchBanners()), [dispatch]);
  const saveBanner = useCallback((values) => dispatch(actions.saveBanner(values)), [dispatch]);
  const deleteBanner = useCallback((id) => dispatch(actions.deleteBanner(id)), [dispatch]);

  return {
    // state
    currentBanner,
    banners,

    // functions
    setCurrentBanner,
    fetchBanners,
    saveBanner,
    deleteBanner,
  };
};
