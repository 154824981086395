// External Imports
import { useState, useMemo } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";

// Internal Imports
import { useAdminUsers } from "hooks/useAdminUsers";
import DeleteModal from "components/DeleteModal";
import AdminUserModal from "./AdminUserModal";
import DataTable from "components/DataTable";

const schema = [
  {
    label: "Name",
    value: "name",
    sortFns: {
      asc: (a, b) => a.name.localeCompare(b.name),
      desc: (a, b) => b.name.localeCompare(a.name),
    },
    formatFn: (r) => r.name || "",
  },
  {
    label: "Content Access",
    value: "content",
    sortFns: {
      asc: (a, b) => a.content - b.content,
      desc: (a, b) => b.content - a.content,
    },
    formatFn: (r) => (r.content ? "Yes" : "No"),
  },
  {
    label: "Card Access",
    value: "cards",
    sortFns: {
      asc: (a, b) => (a.cards === b.cards ? 0 : a.cards ? -1 : 1),
      desc: (a, b) => (a.cards === b.cards ? 0 : b.cards ? -1 : 1),
    },
    formatFn: (r) => (r.cards ? "Yes" : "No"),
  },
  {
    label: "Blog Access",
    value: "blogs",
    sortFns: {
      asc: (a, b) => (a.blogs === b.blogs ? 0 : a.blogs ? -1 : 1),
      desc: (a, b) => (a.blogs === b.blogs ? 0 : b.blogs ? -1 : 1),
    },
    formatFn: (r) => (r.blogs ? "Yes" : "No"),
  },
  {
    label: "Recipe Access",
    value: "recipes",
    sortFns: {
      asc: (a, b) => (a.recipes === b.recipes ? 0 : a.recipes ? -1 : 1),
      desc: (a, b) => (a.recipes === b.recipes ? 0 : b.recipes ? -1 : 1),
    },
    formatFn: (r) => (r.recipes ? "Yes" : "No"),
  },

  {
    label: "Keyword Access",
    value: "keywords",
    sortFns: {
      asc: (a, b) => (a.keywords === b.keywords ? 0 : a.keywords ? -1 : 1),
      desc: (a, b) => (a.keywords === b.keywords ? 0 : b.keywords ? -1 : 1),
    },
    formatFn: (r) => (r.keywords ? "Yes" : "No"),
  },
  {
    label: "Distributor Access",
    value: "distributors",
    sortFns: {
      asc: (a, b) => (a.distributors === b.distributors ? 0 : a.distributors ? -1 : 1),
      desc: (a, b) => (a.distributors === b.distributors ? 0 : b.distributors ? -1 : 1),
    },
    formatFn: (r) => (r.distributors ? "Yes" : "No"),
  },
  {
    label: "Vendor Access",
    value: "vendors",
    sortFns: {
      asc: (a, b) => (a.vendors === b.vendors ? 0 : a.vendors ? -1 : 1),
      desc: (a, b) => (a.vendors === b.vendors ? 0 : b.vendors ? -1 : 1),
    },
    formatFn: (r) => (r.vendors ? "Yes" : "No"),
  },
];

const Users = () => {
  const { adminUsers, createAdminUser, updateAdminPermissions, deleteAdminUser } = useAdminUsers();
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [showAddModal, setShowAddModal] = useState(null);
  const [selected, setSelected] = useState(null);

  const formattedUsers = useMemo(() => adminUsers.map((u) => ({ ...u, ...u.permissions })), [adminUsers]);

  const handleCreate = (userValues) => {
    createAdminUser(userValues);
    setShowAddModal(false);
  };

  const handleUpdate = (userValues) => {
    if (selected && selected.id && userValues?.permissions) {
      updateAdminPermissions(selected.id, userValues.permissions);
    }
    setSelected(null);
  };

  const handleDelete = () => {
    if (selected && selected.id) {
      deleteAdminUser(selected.id);
    }
    // close all modals
    setShowDeleteModal(false);
    setShowAddModal(false);
    setSelected(null);
  };

  return (
    <div style={{ padding: "10px 20px", width: "100%" }}>
      <AdminUserModal
        show={showAddModal}
        hide={() => setShowAddModal(false)}
        title="Add Admin User"
        onSave={handleCreate}
        onDelete={() => setShowAddModal(false)}
      />

      <AdminUserModal
        show={!!selected}
        hide={() => setSelected(null)}
        title="Update Admin User"
        adminUser={selected}
        onSave={handleUpdate}
        onDelete={() => setShowDeleteModal(true)}
        onlyPermissions={true}
      />

      <DeleteModal
        show={showDeleteModal}
        hide={() => setShowDeleteModal(false)}
        title="Delete Blog"
        onDelete={handleDelete}
      />

      <DataTable
        data={formattedUsers}
        schema={schema}
        selectedRecord={selected}
        onRecordSelect={setSelected}
        cornerBtn={
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AiOutlinePlusCircle
              style={{ fontSize: 24, color: "black" }}
              onClick={() => setShowAddModal(true)}
            />
          </div>
        }
      />
    </div>
  );
};

export default Users;
