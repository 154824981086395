// External Import
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

// Internal Imports
import * as actions from "state/recipes/actions";

export const useRecipes = () => {
  // state
  const recipes = useSelector((state) => state.recipes);
  const dispatch = useDispatch();

  // functions
  const fetchRecipes = useCallback(() => dispatch(actions.fetchRecipes()), [dispatch]);
  const saveRecipe = useCallback((values) => dispatch(actions.saveRecipe(values)), [dispatch]);
  const deleteRecipe = useCallback((id) => dispatch(actions.deleteRecipe(id)), [dispatch]);

  return {
    // state
    recipes,

    // functions
    fetchRecipes,
    saveRecipe,
    deleteRecipe,
  };
};
