// External Imports
import React from "react";
import { Routes, BrowserRouter } from "react-router-dom";

// Internal Imports
import { useSessionListener } from "hooks/useSessionListener";
import LoadingSpinner from "./LoadingSpinner";
import redirectRoutes from "./redirectRoutes";
import privateRoutes from "./privateRoutes";
import publicRoutes from "./publicRoutes";
import ErrorModal from "./ErrorModal";

const Root = () => {
  document.body.style.overflow = "hidden";
  useSessionListener();

  return (
    <BrowserRouter>
      <LoadingSpinner />
      <ErrorModal />
      <Routes>
        {publicRoutes}
        {privateRoutes}
        {redirectRoutes}
      </Routes>
    </BrowserRouter>
  );
};

export default Root;
