// External Imports
import { useState } from "react";
import { AiOutlinePlusCircle, AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { MdOutlinePhotoSizeSelectActual } from "react-icons/md";

// Internal Imports
import DeleteModal from "components/DeleteModal";
import { useContent } from "hooks/useContent";
import BannerViewer from "./BannerViewer";
import BannerModal from "./BannerModal";

const Banner = ({ banner, isCurrent, onSelect, onEdit, onDelete }) => {
  if (banner) {
    return (
      <div
        style={{
          position: "relative",
          textAlign: "center",
          background: isCurrent ? "#BEBEBE" : "white",
          width: "45%",
          borderRadius: 15,
          fontSize: 20,
          padding: 10,
          height: 250,
          margin: 15,
        }}
      >
        <div
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 5px",
            display: "flex",
          }}
        >
          {banner.title} {isCurrent && "(Current)"}
          <div style={{ display: "flex", alignItems: "center" }}>
            <AiOutlineEye
              style={{ fontSize: 24, top: 10, right: 10, marginRight: 5 }}
              onClick={() => onSelect(banner)}
            />
            <AiOutlineDelete
              style={{ fontSize: 24, top: 10, right: 10, marginRight: 5, color: "#8B0000" }}
              onClick={() => onDelete(banner)}
            />
            <AiOutlineEdit style={{ fontSize: 24, top: 10, right: 10 }} onClick={() => onEdit(banner)} />
          </div>
        </div>

        {banner.photo?.downloadURL ? (
          <img
            src={banner.photo?.downloadURL}
            alt="banner"
            style={{ height: "90%", width: "100%", objectFit: "cover", borderRadius: 15 }}
            onClick={() => onSelect(banner)}
          />
        ) : (
          <div
            onClick={() => onSelect(banner)}
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              height: "90%",
              width: "100%",
            }}
          >
            <MdOutlinePhotoSizeSelectActual style={{ fontSize: 150 }} />
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

const Content = () => {
  const { banners, currentBanner, saveBanner, deleteBanner, setCurrentBanner } = useContent();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selected, setSelected] = useState(false);

  const handleSelect = (banner) => {
    setSelected(banner);
    setShowViewModal(true);
  };

  const handleSave = (values) => {
    saveBanner(values);
    setShowAddModal(false);
    setShowEditModal(false);
  };

  const handleDelete = () => {
    if (selected.id) {
      deleteBanner(selected.id);
    }
    setShowDeleteModal(false);
    setShowEditModal(false);
    setShowAddModal(false);
    setSelected(null);
  };

  const handleEditSelect = (banner) => {
    setSelected(banner);
    setShowEditModal(true);
  };

  const handleDeleteSelect = (banner) => {
    setSelected(banner);
    setShowDeleteModal(true);
  };

  const handleSetCurrent = (banner) => {
    setCurrentBanner(banner);
    setShowViewModal(false);
  };

  return (
    <>
      <BannerViewer
        show={showViewModal && !!selected}
        hide={() => setShowViewModal(false)}
        banner={selected}
        currentBanner={currentBanner}
        setCurrentBanner={handleSetCurrent}
      />

      <BannerModal
        show={showAddModal}
        hide={() => setShowAddModal(false)}
        title="New Banner"
        onSave={handleSave}
        onDelete={() => setShowAddModal(false)}
      />

      <BannerModal
        show={showEditModal && !!selected}
        hide={() => setShowEditModal(false)}
        title="New Banner"
        banner={selected}
        onSave={handleSave}
        onDelete={() => setShowDeleteModal(true)}
      />

      <DeleteModal
        show={showDeleteModal}
        hide={() => setShowDeleteModal(false)}
        title="Delete Banner"
        onDelete={handleDelete}
      />

      <div
        style={{
          flexFlow: "column",
          display: "flex",
          width: "100%",
          padding: 30,
        }}
      >
        <div
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
            color: "white",
            fontSize: 40,
          }}
        >
          Banners
          <AiOutlinePlusCircle onClick={() => setShowAddModal(true)} />
        </div>

        <div
          style={{
            justifyContent: "space-between",
            alignContent: "flex-start",
            flexWrap: "wrap",
            overflow: "auto",
            display: "flex",
            flexGrow: 1,
          }}
        >
          {banners.map((b) => (
            <Banner
              key={b.id}
              banner={b}
              isCurrent={b.id === currentBanner}
              onSelect={handleSelect}
              onEdit={handleEditSelect}
              onDelete={handleDeleteSelect}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Content;
