// External Imports
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";

// Internal Imports
import { ElGuapoError } from "errors/ElGuapoError";

export const fetchAdminUsers = async () => {
  // make sure they are logged in
  // const { currentUser } = getAuth();
  // if (!currentUser) {
  //   throw new ElGuapoError("You must be logged in to do this");
  // }

  try {
    const functions = getFunctions();
    const callable = httpsCallable(functions, "fetchAdminUsers");
    const { data } = await callable();
    return data;
  } catch (e) {
    console.error("Failed to get admin users", e);
    throw new ElGuapoError("Failed to get admin users");
  }
};

export const createAdminUser = async ({ email, password, name, permissions }) => {
  // make sure they are logged in
  const { currentUser } = getAuth();
  if (!currentUser) {
    throw new ElGuapoError("You must be logged in to do this");
  }

  try {
    const functions = getFunctions();
    const callable = httpsCallable(functions, "createAdminUser");
    const { data } = await callable({ email, password, name, permissions });
    return data;
  } catch (e) {
    console.error("Failed to create admin user", e);
    throw new ElGuapoError("Failed to create admin user");
  }
};

export const updateAdminUser = async (userId, values) => {
  // make sure they are logged in
  const { currentUser } = getAuth();
  if (!currentUser) {
    throw new ElGuapoError("You must be logged in to do this");
  }

  try {
    const functions = getFunctions();
    const callable = httpsCallable(functions, "updateAdminUser");
    const { data } = await callable(userId, values);
    return data;
  } catch (e) {
    console.error("Failed to update admin user", e);
    throw new ElGuapoError("Failed to update admin user");
  }
};

export const updateAdminPermissions = async (userId, permissions) => {
  // make sure they are logged in
  const { currentUser } = getAuth();
  if (!currentUser) {
    throw new ElGuapoError("You must be logged in to do this");
  }

  try {
    const functions = getFunctions();
    const callable = httpsCallable(functions, "updateAdminPermissions");
    const { data } = await callable({ userId, permissions });
    return data;
  } catch (e) {
    console.error("Failed to update admin user permissions", e);
    throw new ElGuapoError("Failed to update admin user permissions");
  }
};

export const deleteAdminUser = async (userId) => {
  // make sure they are logged in
  const { currentUser } = getAuth();
  if (!currentUser) {
    throw new ElGuapoError("You must be logged in to do this");
  }

  try {
    const functions = getFunctions();
    const callable = httpsCallable(functions, "deleteAdminUser");
    const { data } = await callable({ userId });
    return data;
  } catch (e) {
    console.error("Failed to delete admin user", e);
    throw new ElGuapoError("Failed to delete admin user");
  }
};
