// External Imports
import { Route, Navigate, useParams } from "react-router-dom";

// Internal Imports
import { useAuth } from "hooks/useAuth";

// Pages
import Dashboard from "pages/Dashboard";

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, permissions } = useAuth();
  const { tab } = useParams();
  if (isAuthenticated) {
    if (tab === "home") {
      return children;
    } else if (permissions[tab] || permissions.superuser) {
      return children;
    } else {
      return <Navigate to="/dashboard/home" />;
    }
  } else {
    return <Navigate to="/" />;
  }
};

const privateRoutes = [
  <Route
    key="dashboard"
    exact
    path="/dashboard/:tab"
    element={
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    }
  />,
];

export default privateRoutes;
