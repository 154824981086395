// External Imports
import { useState, useEffect } from "react";
import { MdOutlinePhotoSizeSelectActual } from "react-icons/md";

// Internal Imports
import UploadButtonWrapper from "components/UploadButtonWrapper";
import Modal from "components/Modal";

const BannerModal = ({ show, hide, title, banner, onSave, onDelete }) => {
  const [_title, setTitle] = useState("");
  const [photo, setPhoto] = useState("");

  useEffect(() => {
    if (banner) {
      setTitle(banner.title || "");
      setPhoto(banner.photo || null);
    } else {
      reset();
    }
  }, [banner]);

  const reset = () => {
    setTitle("");
    setPhoto(null);
  };

  const handleSave = () => {
    onSave({
      id: banner?.id || null,
      title: _title,
      photo,
    });
  };

  return (
    <Modal show={show} hide={hide} title={title || "Edit Location"} style={{ width: 400 }}>
      <UploadButtonWrapper
        onChange={(files) => files.length > 0 && setPhoto(files[0])}
        style={{
          justifyContent: "center",
          alignItems: "center",
          margin: "30px 0px",
          display: "flex",
          height: 250,
        }}
      >
        {photo && photo.downloadURL ? (
          <img
            src={photo.downloadURL}
            alt="recipe"
            style={{ height: "100%", objectFit: "cover", borderRadius: 14 }}
          />
        ) : (
          <MdOutlinePhotoSizeSelectActual style={{ fontSize: 200 }} />
        )}
      </UploadButtonWrapper>

      <div style={{ margin: "10px 0px", display: "flex", fontSize: 18 }}>
        <input
          placeholder="Title"
          style={{ width: "100%", fontSize: 16 }}
          value={_title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-around", marginTop: 20 }}>
        <button style={{ padding: "5px 10px", fontSize: 16 }} onClick={() => onDelete()}>
          Delete
        </button>

        <button style={{ padding: "5px 10px", fontSize: 16 }} onClick={() => handleSave()}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default BannerModal;
