// External Imports
import { useMemo } from "react";
import { AiOutlineDelete } from "react-icons/ai";

// Internal Imports
import { capitalize } from "utils";

const Keyword = ({ keyword, onDelete }) => {
  if (keyword) {
    return (
      <div
        style={{
          justifyContent: "space-between",
          padding: "5px 7px 5px 14px",
          alignItems: "center",
          background: "white",
          margin: "10px 10px",
          display: "flex",
          borderRadius: 15,
        }}
      >
        <div style={{ fontSize: 18, color: "black" }}>{capitalize(keyword.value || "")}</div>
        <AiOutlineDelete
          style={{ fontSize: 24, top: 10, right: 10, marginRight: 5, color: "#8B0000" }}
          onClick={() => onDelete(keyword)}
        />
      </div>
    );
  } else {
    return null;
  }
};

const KeywordList = ({ title, keywords, onDelete, width }) => {
  return (
    <div
      style={{
        textAlign: "center",
        flexFlow: "column",
        display: "flex",
        color: "white",
        fontSize: 24,
        width,
      }}
    >
      {title}
      {keywords.map((kw) => (
        <Keyword key={`${kw.value}-${kw.category}`} keyword={kw} onDelete={onDelete} />
      ))}
    </div>
  );
};

const columnWidth = "20%";
const KeywordPanel = ({ keywords, handleDelete }) => {
  const spiritKeywords = useMemo(() => keywords.filter((kw) => kw.category === "spirit"), [keywords]);
  const characteristicKeywords = useMemo(
    () => keywords.filter((kw) => kw.category === "characteristic"),
    [keywords]
  );
  const occasionKeywords = useMemo(() => keywords.filter((kw) => kw.category === "occasion"), [keywords]);
  const foodKeywords = useMemo(() => keywords.filter((kw) => kw.category === "food"), [keywords]);
  const topicsKeywords = useMemo(() => keywords.filter((kw) => kw.category === "topics"), [keywords]);

  return (
    <div
      style={{
        justifyContent: "space-between",
        flexWrap: "wrap",
        overflow: "auto",
        display: "flex",
        marginTop: 30,
        flexGrow: 1,
      }}
    >
      <KeywordList title="Spirit" keywords={spiritKeywords} width={columnWidth} onDelete={handleDelete} />
      <KeywordList
        title="Characteristic"
        keywords={characteristicKeywords}
        width={columnWidth}
        onDelete={handleDelete}
      />
      <KeywordList title="Occasion" keywords={occasionKeywords} width={columnWidth} onDelete={handleDelete} />
      <KeywordList title="Food" keywords={foodKeywords} width={columnWidth} onDelete={handleDelete} />
      <KeywordList title="Topics" keywords={topicsKeywords} width={columnWidth} onDelete={handleDelete} />
    </div>
  );
};

export default KeywordPanel;
