// External Imports
import { MdOutlinePhotoSizeSelectActual } from "react-icons/md";

// Internal Imports
import Modal from "components/Modal";

const BannerViewer = ({ show, hide, banner, currentBanner, setCurrentBanner }) => {
  if (banner && banner.id) {
    return (
      <Modal
        show={show}
        hide={hide}
        title={banner?.title || "Banner"}
        style={{ maxHeight: 700, maxWidth: 700 }}
      >
        {banner?.photo?.downloadURL ? (
          <img
            src={banner?.photo?.downloadURL}
            alt="banner-view"
            style={{ maxHeight: 650, maxWidth: "100%", objectFit: "cover", borderRadius: 15 }}
          />
        ) : (
          <MdOutlinePhotoSizeSelectActual style={{ fontSize: 150 }} />
        )}

        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
          {banner.id === currentBanner ? (
            <button style={{ padding: 10, borderRadius: 10 }}>Already current banner</button>
          ) : (
            <button onClick={() => setCurrentBanner(banner)} style={{ padding: 10, borderRadius: 10 }}>
              Set as current banner
            </button>
          )}
        </div>
      </Modal>
    );
  } else {
    return null;
  }
};

export default BannerViewer;
