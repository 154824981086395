// External Imports
import { useState, useEffect } from "react";
import { MdOutlinePhotoSizeSelectActual } from "react-icons/md";
import Toggle from "react-toggle";

// Internal Imports
import UploadButtonWrapper from "components/UploadButtonWrapper";
import Modal from "components/Modal";

// Styling
import "react-toggle/style.css";

const CardModal = ({ show, hide, title, card, onSave, onDelete }) => {
  const [_title, setTitle] = useState("");
  const [photo, setPhoto] = useState("");
  const [inventory, setIsInventory] = useState("");
  const [isAvailable, setIsAvailable] = useState(false);

  useEffect(() => {
    if (card) {
      setTitle(card.title || "");
      setPhoto(card.photo || null);
      setIsInventory(card.inventory || 0);
      setIsAvailable(card.isAvailable || false);
    } else {
      reset();
    }
  }, [card]);

  const reset = () => {
    setTitle("");
    setPhoto(null);
    setIsInventory(0);
    setIsAvailable(false);
  };

  const handleSave = () => {
    onSave({
      id: card?.id || null,
      title: _title,
      photo,
      inventory,
      isAvailable,
    });
  };

  return (
    <Modal show={show} hide={hide} title={title || "Edit Location"} style={{ width: 400 }}>
      <UploadButtonWrapper
        onChange={(files) => files.length > 0 && setPhoto(files[0])}
        style={{
          justifyContent: "center",
          alignItems: "center",
          margin: "30px 0px",
          display: "flex",
          height: 380,
        }}
      >
        {photo && photo.downloadURL ? (
          <img
            src={photo.downloadURL}
            alt="recipe"
            style={{ height: "100%", objectFit: "cover", borderRadius: 14 }}
          />
        ) : (
          <MdOutlinePhotoSizeSelectActual style={{ fontSize: 200 }} />
        )}
      </UploadButtonWrapper>

      <div style={{ margin: "10px 0px", display: "flex", fontSize: 18, alignItems: "center" }}>
        Title:
        <input
          placeholder="Title"
          style={{ width: "100%", fontSize: 16, marginLeft: 10 }}
          value={_title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <div style={{ margin: "10px 0px", display: "flex", fontSize: 18, alignItems: "center" }}>
        Inventory:
        <input
          type="number"
          placeholder="Inventory"
          style={{ width: "100%", fontSize: 16, marginLeft: 10 }}
          value={inventory}
          onChange={(e) => setIsInventory(e.target.value)}
        />
      </div>

      <div style={{ margin: "10px 0px", display: "flex", fontSize: 18, alignItems: "center" }}>
        <div style={{ display: "flex" }}>
          Status:
          <div style={{ color: isAvailable ? "green" : "red", marginLeft: 5 }}>
            {isAvailable ? "Availabile" : "Unavailable"}
          </div>
        </div>

        <div style={{ width: "100%", textAlign: "center" }}>
          <Toggle checked={isAvailable} onChange={(e) => setIsAvailable(e.target.checked)} />
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "space-around", marginTop: 20 }}>
        <button style={{ padding: "5px 10px", fontSize: 16 }} onClick={() => onDelete()}>
          Delete
        </button>

        <button style={{ padding: "5px 10px", fontSize: 16 }} onClick={() => handleSave()}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default CardModal;
