// External Imports
import { useMemo } from "react";
import CreatableSelect from "react-select/creatable";

// Internal Imports
import { useFilters } from "hooks/useFilters";
import { capitalize } from "utils";

const TagSelect = ({ value, onChange, style = {} }) => {
  const { tags, saveTag } = useFilters();
  const tagOptions = useMemo(() => tags.map((tag) => ({ value: tag, label: capitalize(tag) })), [tags]);
  const values = useMemo(
    () => value.map((t) => tagOptions.find((to) => to.value === t)),
    [tagOptions, value]
  );

  const handleChange = (values) => {
    onChange(values.map((tag) => tag.value));
  };

  const handleCreate = (tagStr) => {
    const newTag = tagStr.toLowerCase();
    saveTag(newTag);
    onChange([...value, newTag]);
  };

  return (
    <div style={style}>
      <CreatableSelect
        options={tagOptions}
        isClearable={true}
        value={values}
        onChange={handleChange}
        onCreateOption={handleCreate}
        isMulti={true}
        isSearchable={true}
        placeholder="Tags"
        noOptionsMessage={() => "No matching tags"}
      />
    </div>
  );
};

export default TagSelect;
