const ScreenBlur = ({ background = "black", opacity = 0.25, onClick, children }) => {
  return (
    <div
      onClick={() => onClick && onClick()}
      style={{
        justifyContent: "center",
        position: "absolute",
        alignItems: "center",
        display: "flex",
        height: "100%",
        width: "100%",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          background,
          opacity,
        }}
      ></div>
      <div style={{ zIndex: 1 }}>{children}</div>
    </div>
  );
};

export default ScreenBlur;
