// Internal Imports
import { login as fireLogin, logout as fireLogout } from "services/auth";
import { setLoading, logError } from "state/status/actions";
import { types } from "./types";

export const login = (email, password) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await fireLogin(email, password);
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem loging in"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const logout = () => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await fireLogout();
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem logging out"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const shadowLogin = (user, permissions) => ({
  type: types.LOGIN,
  payload: { user, permissions },
});

export const shadowLogout = () => ({
  type: types.LOGOUT,
});
