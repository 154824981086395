// External Import
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

// Internal Imports
import * as actions from "state/filters/actions";

export const useFilters = () => {
  // state
  const keywords = useSelector((state) => state.filters.keywords) || [];
  const tags = useSelector((state) => state.filters.tags) || [];
  const dispatch = useDispatch();

  // functions
  const fetchKeywords = useCallback(() => dispatch(actions.fetchKeywords()), [dispatch]);
  const saveKeyword = useCallback((keyword) => dispatch(actions.saveKeyword(keyword)), [dispatch]);
  const deleteKeyword = useCallback((keyword) => dispatch(actions.deleteKeyword(keyword)), [dispatch]);
  const fetchTags = useCallback(() => dispatch(actions.fetchTags()), [dispatch]);
  const saveTag = useCallback((tag) => dispatch(actions.saveTag(tag)), [dispatch]);
  const deleteTag = useCallback((tag) => dispatch(actions.deleteTag(tag)), [dispatch]);

  return {
    // state
    keywords,
    tags,

    // functions
    fetchKeywords,
    saveKeyword,
    deleteKeyword,
    fetchTags,
    saveTag,
    deleteTag,
  };
};
