// External Imports
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { MdOutlinePhotoSizeSelectActual } from "react-icons/md";

const PostItem = ({ post, selected, onClick, onEdit, onDelete }) => {
  if (post) {
    return (
      <div
        onClick={() => onClick(post)}
        style={{
          background: selected ? "#ADD8E6" : "white",
          justifyContent: "space-between",
          boxShadow: "2px 2px 2px grey",
          alignItems: "center",
          padding: "15px 15px",
          display: "flex",
          borderRadius: 15,
          marginBottom: 15,
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", justifyContent: "center", height: 150, width: 150 }}
        >
          {post.photo && post.photo.downloadURL ? (
            <img
              src={post.photo.downloadURL}
              alt="blog"
              style={{ height: "100%", width: "100%", objectFit: "cover", borderRadius: 14 }}
            />
          ) : (
            <MdOutlinePhotoSizeSelectActual style={{ fontSize: 100 }} />
          )}
        </div>

        <div>
          <p style={{ fontSize: 18, marginBottom: 5 }}>{post.title}</p>
          <p style={{ fontSize: 12 }}>{post.author}</p>
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <AiOutlineEdit style={{ fontSize: 20, color: "black", marginBottom: 10 }} onClick={onEdit} />
          <AiOutlineDelete style={{ fontSize: 20, color: "#8B0000" }} onClick={onDelete} />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const PostList = ({ posts, selected, onClick, onEdit, onDelete }) => {
  if (Array.isArray(posts)) {
    return posts.map((l, idx) => (
      <PostItem
        key={l.id}
        post={l}
        selected={l.id === selected?.id}
        onClick={onClick}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    ));
  } else {
    return null;
  }
};

export default PostList;
