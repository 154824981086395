// Internal Imports
import { setLoading, logError } from "state/status/actions";
import { types } from "./types";
import {
  updateAdminPermissions as fireUpdateAdminPermissions,
  fetchAdminUsers as fireFetchAdminUsers,
  createAdminUser as fireCreateAdminUser,
  updateAdminUser as fireUpdateAdminUser,
  deleteAdminUser as fireDeleteAdminUser,
} from "services/firestore/adminUsers";

export const fetchAdminUsers = () => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const adminUsers = await fireFetchAdminUsers();
      dispatch({
        type: types.SET_ADMIN_USERS,
        payload: adminUsers,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem getting admin users"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const createAdminUser = ({ email, password, name, permissions }) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const adminUser = await fireCreateAdminUser({ email, password, name, permissions });
      dispatch({
        type: types.CREATE_ADMIN_USER,
        payload: adminUser,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem saving this admin user"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const updateAdminUser = (userId, values) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const updates = await fireUpdateAdminUser(userId, values);
      dispatch({
        type: types.UPDATE_ADMIN_USER,
        payload: { ...updates, id: userId },
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem saving this admin user"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const updateAdminPermissions = (userId, permissions) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const updatedPermissions = await fireUpdateAdminPermissions(userId, permissions);
      dispatch({
        type: types.UPDATE_ADMIN_USER,
        payload: { permissions: updatedPermissions, id: userId },
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem saving this admin user"));
      }
    }

    dispatch(setLoading(false));
  };
};
export const deleteAdminUser = (userId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await fireDeleteAdminUser(userId);
      dispatch({
        type: types.DELETE_ADMIN_USER,
        payload: userId,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem deleting this admin user"));
      }
    }

    dispatch(setLoading(false));
  };
};
