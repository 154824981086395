// External Imports
import { getFirestore, collection, deleteDoc, updateDoc, getDocs, addDoc, doc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Internal Imports
import { ElGuapoError } from "errors/ElGuapoError";
import { removeUndefined } from "utils";

export const fetchDistributors = async () => {
  const db = getFirestore();
  const distributorsRef = await getDocs(collection(db, "DISTRIBUTORS")).catch((e) => {
    throw new ElGuapoError(e.message);
  });

  return distributorsRef.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
};

export const createDistributor = async ({
  name,
  address,
  city,
  state,
  country,
  zip,
  phone,
  website,
  bitters,
  mixers,
  syrups,
  canBuy,
  canDrink,
  latitude,
  longitude,
}) => {
  const values = removeUndefined({
    name,
    address,
    city,
    state,
    country,
    zip,
    phone,
    website,
    bitters,
    mixers,
    syrups,
    canBuy,
    canDrink,
    latitude,
    longitude,
  });

  if (!values.name) {
    throw new ElGuapoError("Please enter the distributor's name.");
  }

  // update user in firestore
  const { currentUser } = getAuth();
  if (!currentUser) {
    throw new ElGuapoError("You must be logged in to add a distributor");
  }

  const distributor = {
    ...values,
    createdAt: new Date(),
    updatedAt: new Date(),
  };

  const db = getFirestore();
  const distributorRef = await addDoc(collection(db, "DISTRIBUTORS"), distributor).catch((e) => {
    throw new ElGuapoError(e.message);
  });

  return { ...distributor, id: distributorRef.id };
};

export const updateDistributor = async (
  distributorId,
  {
    name,
    address,
    city,
    state,
    country,
    zip,
    phone,
    website,
    bitters,
    mixers,
    syrups,
    canBuy,
    canDrink,
    latitude,
    longitude,
  }
) => {
  const values = removeUndefined({
    name,
    address,
    city,
    state,
    country,
    zip,
    phone,
    website,
    bitters,
    mixers,
    syrups,
    canBuy,
    canDrink,
    latitude,
    longitude,
  });

  if (!distributorId) {
    throw new ElGuapoError("Please select what distributor you would like to update.");
  }

  if (Object.keys.length > 0) {
    values.updatedAt = new Date();
    const db = getFirestore();
    await updateDoc(doc(db, "DISTRIBUTORS", distributorId), values).catch((e) => {
      throw new ElGuapoError(e.message);
    });

    return values;
  } else {
    return {};
  }
};

export const deleteDistributor = async (distributorId) => {
  if (!distributorId) {
    throw new ElGuapoError("Please select what distributor you would like to delete.");
  }

  const db = getFirestore();
  return deleteDoc(doc(db, "DISTRIBUTORS", distributorId)).catch((e) => {
    throw new ElGuapoError(e.message);
  });
};
