// Internal Imports
import { setLoading, logError } from "state/status/actions";
import { types } from "./types";
import {
  setCurrentBanner as fireSetCurrentBanner,
  fetchBanners as fireFetchBanners,
  deleteBanner as fireDeleteBanner,
  saveBanner as fireSaveBanner,
} from "services/firestore/content";

export const fetchBanners = () => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const banners = await fireFetchBanners();
      dispatch({
        type: types.SET_BANNERS,
        payload: banners,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem getting banners"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const saveBanner = (values) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const updates = await fireSaveBanner(values);
      dispatch({
        type: types.SAVE_BANNER,
        payload: updates,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem saving this banner"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const deleteBanner = (bannerId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await fireDeleteBanner(bannerId);
      dispatch({
        type: types.DELETE_BANNER,
        payload: bannerId,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem deleting this banner"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const setCurrentBanner = (banner) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const current = await fireSetCurrentBanner(banner);
      dispatch({
        type: types.SET_CURRENT_BANNER,
        payload: current,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem setting current banner"));
      }
    }

    dispatch(setLoading(false));
  };
};
