// External Imports
import React, { useEffect, useRef } from "react";

const Map = ({ map, setMap, children, style, ...options }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && !map) {
      const mapInstance = new window.google.maps.Map(ref.current);
      mapInstance.setOptions(options);
      setMap(mapInstance);
    }

    // eslint-disable-next-line
  }, [ref.current]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

export default Map;
