// External Imports
import { Provider } from "react-redux";

// Internal Imports
import Root from "./Root";

// Styling
import "./App.css";

// Production Store
import PersistedStore from "./state/persistedStore";
const store = PersistedStore.getDefaultStore().store;

const App = () => {
  // turn off console.log() when in production
  if (process.env.REACT_APP_MODE !== "LOCAL") {
    const noop = () => {};
    console.log = noop;
    console.warn = noop;
    console.error = noop;
  }

  return (
    <Provider store={store}>
      <Root />
    </Provider>
  );
};

export default App;
