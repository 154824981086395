// External Imports
import { useState, useEffect } from "react";

const Marker = ({ index, location, map, onClick }) => {
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker && map && location && location.latitude && location.longitude) {
      const position = {
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude),
      };

      marker.setOptions({ position });
      marker.addListener("click", () => {
        map.panTo(position);
        onClick(index);
      });
    }
    // eslint-disable-next-line
  }, [index, marker, location]);

  return null;
};

export default Marker;
