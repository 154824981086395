// External Import
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

// Internal Imports
import * as actions from "state/adminUsers/actions";

export const useAdminUsers = () => {
  // state
  const adminUsers = useSelector((state) => state.adminUsers);
  const dispatch = useDispatch();

  // functions
  const fetchAdminUsers = useCallback(() => dispatch(actions.fetchAdminUsers()), [dispatch]);
  const createAdminUser = useCallback(
    ({ email, password, name, permissions }) =>
      dispatch(actions.createAdminUser({ email, password, name, permissions })),
    [dispatch]
  );
  const updateAdminUser = useCallback(
    (userId, values) => dispatch(actions.updateAdminUser(userId, values)),
    [dispatch]
  );
  const deleteAdminUser = useCallback((userId) => dispatch(actions.deleteAdminUser(userId)), [dispatch]);
  const updateAdminPermissions = useCallback(
    (userId, permissions) => dispatch(actions.updateAdminPermissions(userId, permissions)),
    [dispatch]
  );

  return {
    // state
    adminUsers,

    // functions
    fetchAdminUsers,
    createAdminUser,
    updateAdminUser,
    deleteAdminUser,
    updateAdminPermissions,
  };
};
