// External Imports
import produce from "immer";

// Internal Imports
import { types as authTypes } from "state/auth/types";
import { types } from "./types";

export const initialState = {
  loading: false,
  error: "",
};

export default function status(state = initialState, { type, payload }) {
  return produce(state, (draft) => {
    switch (type) {
      case authTypes.LOGOUT: {
        return initialState;
      }

      case types.IS_LOADING: {
        draft.loading = payload;
        break;
      }

      case types.LOG_ERROR: {
        draft.error = payload;
        break;
      }

      case types.CLEAR_ERROR: {
        draft.error = "";
        break;
      }

      default: {
        return state;
      }
    }
  });
}
