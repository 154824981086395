// External Imports
import RingLoader from "react-spinners/RingLoader";

// Internal Imports
import ScreenBlur from "components/ScreenBlur";
import { useStatus } from "hooks/useStatus";

const size = 150;

const LoadingSpinner = () => {
  const { loading } = useStatus();

  if (loading) {
    return (
      <ScreenBlur background="black" opacity={0.2}>
        <div
          style={{
            position: "absolute",
            margin: "auto",
            height: size,
            width: size,
            bottom: 100,
            right: 0,
            left: 0,
            top: 0,
          }}
        >
          <RingLoader color="black" loading={loading} size={size} />
        </div>
      </ScreenBlur>
    );
  } else {
    return null;
  }
};

export default LoadingSpinner;
