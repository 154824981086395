// External Imports
import { AiOutlineDelete } from "react-icons/ai";

// Internal Imports
import Modal from "./Modal";

const DeleteModal = ({ show, hide, message, onDelete }) => {
  const handleDelete = () => {
    onDelete();
    hide();
  };

  return (
    <Modal show={show} hide={hide} title="Delete">
      <div style={{ textAlign: "center" }}>
        <AiOutlineDelete style={{ fontSize: 50, color: "red" }} />
        <p>{message || "Are you sure you want to delete this?"}</p>
      </div>

      <div style={{ display: "flex", justifyContent: "space-around", marginTop: 20 }}>
        <button style={{ padding: "5px 10px", fontSize: 16 }} onClick={() => hide()}>
          Cancel
        </button>

        <button style={{ padding: "5px 10px", fontSize: 16 }} onClick={() => handleDelete()}>
          Delete
        </button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
