// External Imports
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";

const LocationItem = ({ index, selected, name, address, state, zip, onClick, onEdit, onDelete }) => {
  return (
    <div
      onClick={() => onClick(index)}
      style={{
        background: selected ? "#ADD8E6" : "white",
        justifyContent: "space-between",
        boxShadow: "2px 2px 2px grey",
        alignItems: "center",
        padding: "5px 15px",
        display: "flex",
        borderRadius: 15,
        marginBottom: 15,
      }}
    >
      <div>
        <p style={{ fontSize: 18, marginBottom: 5 }}>{name}</p>
        <p style={{ fontSize: 12 }}>
          {address} {state}, {zip}
        </p>
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <AiOutlineEdit style={{ fontSize: 20, color: "black", marginBottom: 10 }} onClick={onEdit} />
        <AiOutlineDelete style={{ fontSize: 20, color: "red" }} onClick={onDelete} />
      </div>
    </div>
  );
};

const LocationList = ({ locations, selectedIndex, onClick, onEdit, onDelete }) => {
  return (
    <div style={{ overflow: "auto", height: "100%" }}>
      {Array.isArray(locations) ? (
        locations.map((l, idx) => (
          <LocationItem
            key={l.id}
            index={idx}
            selected={idx === selectedIndex}
            name={l.name}
            address={l.address}
            state={l.state}
            zip={l.zip}
            onClick={onClick}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ))
      ) : (
        <p>No Locations</p>
      )}
    </div>
  );
};

export default LocationList;
