// Internal Imports
import { setLoading, logError } from "state/status/actions";
import { types } from "./types";
import {
  fetchKeywords as fireFetchKeywords,
  deleteKeyword as fireDeleteKeyword,
  saveKeyword as fireSaveKeyword,
  fetchTags as fireFetchTags,
  deleteTag as fireDeleteTag,
  saveTag as fireSaveTag,
} from "services/firestore/filters";

export const fetchKeywords = () => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const keywords = await fireFetchKeywords();
      dispatch({
        type: types.SET_KEYWORDS,
        payload: keywords,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem getting keywords"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const saveKeyword = (keyword) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await fireSaveKeyword(keyword);
      dispatch({
        type: types.SAVE_KEYWORD,
        payload: keyword,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem saving this keyword"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const deleteKeyword = (keyword) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await fireDeleteKeyword(keyword);
      dispatch({
        type: types.DELETE_KEYWORD,
        payload: keyword,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem deleting this keyword"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const fetchTags = () => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const tags = await fireFetchTags();
      dispatch({
        type: types.SET_TAGS,
        payload: tags,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem getting tags"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const saveTag = (tag) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await fireSaveTag(tag);
      dispatch({
        type: types.SAVE_TAG,
        payload: tag,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem saving this tag"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const deleteTag = (tag) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await fireDeleteTag(tag);
      dispatch({
        type: types.DELETE_TAG,
        payload: tag,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem deleting this tag"));
      }
    }

    dispatch(setLoading(false));
  };
};
