// External Import
import { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

// Internal Imports
import * as actions from "state/blogs/actions";

export const useBlogs = () => {
  // state
  const unsortedBlogs = useSelector((state) => state.blogs) || [];
  // eslint-disable-next-line
  const blogs = useMemo(() => [...unsortedBlogs].sort((a, b) => b.updatedAt - a.updatedAt), [unsortedBlogs]);
  const dispatch = useDispatch();

  // functions
  const fetchBlogs = useCallback(() => dispatch(actions.fetchBlogs()), [dispatch]);
  const saveBlog = useCallback((values) => dispatch(actions.saveBlog(values)), [dispatch]);
  const deleteBlog = useCallback((id) => dispatch(actions.deleteBlog(id)), [dispatch]);

  return {
    // state
    blogs,

    // functions
    fetchBlogs,
    saveBlog,
    deleteBlog,
  };
};
