// External Imports
import { signInWithEmailAndPassword, signOut, getAuth } from "firebase/auth";

// Internal Import
import { ElGuapoError } from "errors/ElGuapoError";

export const login = async (email, password) => {
  if (!email) {
    throw new ElGuapoError("Please enter a valid email.");
  }

  if (!password) {
    throw new ElGuapoError("Please enter a valid password.");
  }

  const auth = getAuth();
  await signInWithEmailAndPassword(auth, email, password).catch((e) => {
    if (e.code === "auth/wrong-password") {
      throw new ElGuapoError("Incorrect password");
    } else {
      throw new ElGuapoError("Incorrect email or password");
    }
  });
};

export const logout = () => {
  const auth = getAuth();
  return signOut(auth).catch(() => {
    throw new ElGuapoError("There was an error logging out");
  });
};
