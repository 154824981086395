// External Imports
import { useNavigate } from "react-router-dom";
import { AiOutlineHome, AiOutlineFileSearch, AiOutlineIdcard } from "react-icons/ai";
import { MdOutlineSell, MdOutlineLogout } from "react-icons/md";
import { GrTextWrap, GrUserAdmin } from "react-icons/gr";
import { BiDrink, BiBookContent } from "react-icons/bi";
import { GiPathDistance } from "react-icons/gi";

// Internal Imports
import { useAuth } from "hooks/useAuth";

const getPanelConfigs = (permissions) => {
  const panels = [{ label: "Home", tab: "home", icon: AiOutlineHome }];
  if (permissions.content || permissions.superuser) {
    panels.push({ label: "Content", tab: "content", icon: BiBookContent });
  }

  if (permissions.cards || permissions.superuser) {
    panels.push({ label: "Cards", tab: "cards", icon: AiOutlineIdcard });
  }

  if (permissions.blogs || permissions.superuser) {
    panels.push({ label: "Blogs", tab: "blogs", icon: GrTextWrap });
  }

  if (permissions.recipes || permissions.superuser) {
    panels.push({ label: "Recipes", tab: "recipes", icon: BiDrink });
  }

  if (permissions.keywords || permissions.superuser) {
    panels.push({ label: "Keywords", tab: "keywords", icon: AiOutlineFileSearch });
  }

  if (permissions.distributors || permissions.superuser) {
    panels.push({ label: "Distributors", tab: "distributors", icon: GiPathDistance });
  }

  if (permissions.vendors || permissions.superuser) {
    panels.push({ label: "Vendors", tab: "vendors", icon: MdOutlineSell });
  }

  if (permissions.superuser) {
    panels.push({ label: "Users", tab: "users", icon: GrUserAdmin });
  }

  return panels;
};

const TabButton = ({ label, Icon, tab, currentTab }) => {
  const isCurrent = tab === currentTab;
  const navigate = useNavigate();

  const switchTab = () => {
    if (currentTab !== tab) {
      navigate(`/dashboard/${tab}`);
    }
  };

  return (
    <div
      style={{
        background: isCurrent ? "#ADD8E6" : "",
        alignItems: "center",
        display: "flex",
        borderRadius: 10,
        marginTop: 30,
        padding: 10,
      }}
      onClick={() => switchTab()}
    >
      <Icon style={{ marginRight: 8, fontSize: 20 }} />
      {label}
    </div>
  );
};

const Nav = ({ currentTab }) => {
  const { logout, permissions } = useAuth();
  const panelConfigs = getPanelConfigs(permissions);

  return (
    <div
      style={{
        justifyContent: "space-between",
        flexDirection: "column",
        background: "white",
        alignItems: "center",
        padding: 15,
        width: 125,
      }}
    >
      <div style={{ textAlign: "center" }}>
        <img src="/imgs/logo.png" alt="el-guapo" style={{ width: 100, margin: "0px 10px" }} />
      </div>

      {panelConfigs.map((p) => (
        <TabButton key={p.tab} label={p.label} Icon={p.icon} tab={p.tab} currentTab={currentTab} />
      ))}

      <div
        style={{
          alignItems: "center",
          display: "flex",
          marginTop: 30,
          padding: 10,
        }}
        onClick={logout}
      >
        <MdOutlineLogout style={{ marginRight: 8, fontSize: 20 }} />
        Logout
      </div>
    </div>
  );
};

export default Nav;
