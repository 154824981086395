// External Import
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

// Internal Imports
import * as actions from "state/auth/actions";

export const useAuth = () => {
  // state
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const permissions = useSelector((state) => state.auth.permissions) || {};
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  // functions
  const login = useCallback((email, password) => dispatch(actions.login(email, password)), [dispatch]);
  const logout = useCallback(() => dispatch(actions.logout()), [dispatch]);
  const shadowLogin = useCallback(
    (user, permissions) => dispatch(actions.shadowLogin(user, permissions)),
    [dispatch]
  );
  const shadowLogout = useCallback(() => dispatch(actions.shadowLogout()), [dispatch]);

  return {
    // state
    isAuthenticated,
    permissions,
    user,

    // functions
    login,
    logout,
    shadowLogin,
    shadowLogout,
  };
};
