// External Imports
import { useState, useEffect } from "react";

// Internal Imports
import Modal from "components/Modal";

const AdminUserModal = ({ show, hide, title, adminUser, onSave, onDelete, onlyPermissions = false }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [content, setContent] = useState(false);
  const [cards, setCards] = useState(false);
  const [blogs, setBlogs] = useState(false);
  const [recipes, setRecipes] = useState(false);
  const [keywords, setKeywords] = useState(false);
  const [distributors, setDistributors] = useState(false);
  const [vendors, setVendors] = useState(false);

  useEffect(() => {
    if (adminUser) {
      setName(adminUser.name || "");
      setEmail(adminUser.email || "");
      setPassword(adminUser.password || "");
      setContent(adminUser.content || false);
      setCards(adminUser.cards || false);
      setBlogs(adminUser.blogs || false);
      setRecipes(adminUser.recipes || false);
      setKeywords(adminUser.keywords || false);
      setDistributors(adminUser.distributors || false);
      setVendors(adminUser.vendors || false);
    } else {
      reset();
    }
  }, [adminUser]);

  const reset = () => {
    setName("");
    setEmail("");
    setPassword("");
    setContent(false);
    setCards(false);
    setBlogs(false);
    setRecipes(false);
    setKeywords(false);
    setDistributors(false);
    setVendors(false);
  };

  const handleSave = () => {
    onSave({
      name,
      email,
      password,
      permissions: {
        content,
        cards,
        blogs,
        recipes,
        keywords,
        distributors,
        vendors,
      },
    });
  };

  return (
    <Modal
      show={show}
      hide={hide}
      title={title || "Edit Blog"}
      style={{ width: 500, maxHeight: 750, overflowY: "auto" }}
    >
      {!onlyPermissions && (
        <>
          <div style={inputWrapperStyle}>
            <input
              placeholder="Admin Name"
              style={inputStyle}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div style={inputWrapperStyle}>
            <input
              placeholder="example@elguapo.com"
              style={inputStyle}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div style={inputWrapperStyle}>
            <input
              placeholder="$uper$ecretP@ss0rd"
              style={inputStyle}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div style={inputWrapperStyle}>
            <input
              placeholder="Confirm $uper$ecretP@ss0rd"
              style={inputStyle}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        </>
      )}

      <div style={inputWrapperStyle}>Permissions:</div>
      <div style={{ paddingLeft: 30 }}>
        <div style={checkboxWrapperStyle}>
          <input
            type="checkbox"
            style={checkboxStyle}
            checked={content}
            onChange={(e) => setContent(!content)}
          />
          Content
        </div>

        <div style={checkboxWrapperStyle}>
          <input type="checkbox" style={checkboxStyle} checked={cards} onChange={(e) => setCards(!cards)} />
          Cards
        </div>

        <div style={checkboxWrapperStyle}>
          <input type="checkbox" style={checkboxStyle} checked={blogs} onChange={() => setBlogs(!blogs)} />
          Blogs
        </div>

        <div style={checkboxWrapperStyle}>
          <input
            type="checkbox"
            style={checkboxStyle}
            checked={recipes}
            onChange={(e) => setRecipes(!recipes)}
          />
          Recipes
        </div>

        <div style={checkboxWrapperStyle}>
          <input
            type="checkbox"
            style={checkboxStyle}
            checked={keywords}
            onChange={(e) => setKeywords(!keywords)}
          />
          Keywords
        </div>

        <div style={checkboxWrapperStyle}>
          <input
            type="checkbox"
            style={checkboxStyle}
            checked={distributors}
            onChange={(e) => setDistributors(!distributors)}
          />
          Distributors
        </div>

        <div style={checkboxWrapperStyle}>
          <input
            type="checkbox"
            style={checkboxStyle}
            checked={vendors}
            onChange={(e) => setVendors(!vendors)}
          />
          Vendors
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "space-around", marginTop: 40 }}>
        <button style={btnStyle} onClick={() => onDelete()}>
          Delete
        </button>

        <button style={btnStyle} onClick={() => handleSave()}>
          Save
        </button>
      </div>
    </Modal>
  );
};

const inputWrapperStyle = {
  whiteSpace: "nowrap",
  margin: "20px 0px",
  display: "flex",
  fontSize: 18,
};

const inputStyle = {
  width: "100%",
  fontSize: 16,
};

const checkboxWrapperStyle = {
  alignItems: "center",
  margin: "20px 0px",
  display: "flex",
  fontSize: 18,
};

const checkboxStyle = {
  margin: "0px 5px",
};

const btnStyle = {
  padding: "10px 0px",
  borderRadius: 14,
  fontSize: 16,
  width: 150,
};

export default AdminUserModal;
