// External Imports
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject, getBlob } from "firebase/storage";

// Internal Imports
import { ElGuapoError } from "errors/ElGuapoError";

export const uploadFile = async (file, filePath, throwError = false) => {
  if (!file) {
    throw new ElGuapoError("Invalid file");
  }

  if (!filePath) {
    throw new ElGuapoError("Invalid file path");
  }

  const storage = getStorage();
  const fileRef = ref(storage, filePath);

  try {
    const upload = await uploadBytes(fileRef, file);
    const downloadURL = await getDownloadURL(upload.ref);
    return {
      name: file.name,
      id: upload.metadata.name,
      size: upload.metadata.size,
      type: upload.metadata.contentType,
      filePath: upload.metadata.fullPath,
      uploadedAt: upload.metadata.timeCreated,
      updatedAt: upload.metadata.updated,
      downloadURL,
    };
  } catch (e) {
    console.error(e);
    if (throwError) {
      throw ElGuapoError(`There was a problem saving ${file.name}`);
    }
  }
};

export const deleteFile = async (filePath, throwError = false) => {
  const storage = getStorage();
  const fileRef = ref(storage, filePath);

  try {
    await deleteObject(fileRef);
  } catch (e) {
    console.error(e);
    if (throwError) {
      throw ElGuapoError(`There was a problem deleting ${filePath}`);
    }
  }
};

export const copyFile = async (sourcePath, targetPath, throwError = false) => {
  if (!sourcePath) {
    throw new ElGuapoError("Invalid source path");
  }

  if (!targetPath) {
    throw new ElGuapoError("Invalid target path");
  }

  const storage = getStorage();
  const sourceRef = ref(storage, sourcePath);
  const targetRef = ref(storage, targetPath);

  const dataBlob = await getBlob(sourceRef).catch((e) => {
    console.error(e);
    if (throwError) {
      throw ElGuapoError(`There was a problem downloading file.`);
    }
  });

  if (dataBlob) {
    try {
      const upload = await uploadBytes(targetRef, dataBlob);
      const downloadURL = await getDownloadURL(upload.ref);
      return {
        name: "current",
        id: upload.metadata.name,
        size: upload.metadata.size,
        type: upload.metadata.contentType,
        filePath: upload.metadata.fullPath,
        uploadedAt: upload.metadata.timeCreated,
        updatedAt: upload.metadata.updated,
        downloadURL,
      };
    } catch (e) {
      console.error(e);
      if (throwError) {
        throw ElGuapoError(`There was a problem uploading file.`);
      }
    }
  }
};
