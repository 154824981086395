// External Imports
import Client from "shopify-buy";

const shopifyClient = Client.buildClient({
  storefrontAccessToken: process.env.REACT_APP_SHOPIFY_ACCESS_TOKEN,
  domain: process.env.REACT_APP_SHOPIFY_DOMAIN,
});

// Gets Shopify store information
export const getShopDetails = () => shopifyClient.shop.fetchInfo();

// Gets all the products from Shopify
export const getProducts = () => shopifyClient.product.fetchAll();

// Gets individual item based on id
export const getProduct = (id) => shopifyClient.product.fetch(id);

// Creates initial checkout state from Shopify
export const createCheckout = () => shopifyClient.checkout.create();

// Adds variants to cart/checkout
export const addCartItem = (checkoutId, lineItemsToAdd) =>
  shopifyClient.checkout.addLineItems(checkoutId, lineItemsToAdd);

// Removes line item from cart and checkout state
export const removeCartItem = (checkoutId, lineItemId) =>
  shopifyClient.checkout.removeLineItems(checkoutId, [lineItemId]);

// Updates quantity of line items in cart and in checkout state
export const updateQuantity = (lineItemId, quantity, checkoutId) => {
  const lineItemsToUpdate = [{ id: lineItemId, quantity: parseInt(quantity, 10) }];
  shopifyClient.checkout.updateLineItems(checkoutId, lineItemsToUpdate);
};
