// External Imports
import { isValidElement } from "react";
import { AiOutlineSortAscending, AiOutlineSortDescending } from "react-icons/ai";

export const IndexCell = ({ value = "", background, color, borderColor, btn }) => {
  return (
    <div
      style={{
        border: `.5px solid ${borderColor}`,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: 50,
        width: 50,
        background,
        color,
      }}
    >
      {isValidElement(btn) ? btn : value}
    </div>
  );
};

export const HeaderCell = ({
  label,
  value,
  sortValue,
  setSortValue,
  sortDirection,
  setSortDirection,
  background,
  color,
  borderColor,
}) => {
  const isSorting = sortValue && value === sortValue;

  const handleClick = () => {
    if (isSorting) {
      // toggle sortDirection
      setSortDirection(sortDirection === "desc" ? "asc" : "desc");
    } else {
      setSortValue(value);
    }
  };

  return (
    <div
      onClick={() => handleClick()}
      style={{
        border: `.5px solid ${borderColor}`,
        justifyContent: "center",
        alignItems: "center",
        flexBasis: "100%",
        display: "flex",
        fontWeight: 600,
        height: 50,
        background,
        color,
      }}
    >
      {label}
      {isSorting && (
        <div style={{ marginLeft: 10 }}>
          {sortDirection === "desc" ? (
            <AiOutlineSortDescending style={{ fontSize: 24 }} />
          ) : (
            <AiOutlineSortAscending style={{ fontSize: 24 }} />
          )}
        </div>
      )}
    </div>
  );
};

export const Cell = ({ value = "", background, color, borderColor }) => {
  return (
    <div
      style={{
        border: `.5px solid ${borderColor}`,
        justifyContent: "center",
        alignItems: "center",
        flexBasis: "100%",
        display: "flex",
        height: 50,
        background,
        color,
      }}
    >
      {value}
    </div>
  );
};

export const RowItem = ({
  index,
  record,
  schema,
  selectedRecord,
  setSelectedRecord,
  indexBackground,
  indexColor,
  cellBackground,
  cellColor,
  selectedBackground,
  selectedColor,
  borderColor,
}) => {
  const isSelected = selectedRecord && record.id === selectedRecord.id;

  return (
    <div style={{ display: "flex" }} onClick={() => setSelectedRecord(record)}>
      <IndexCell
        value={index + 1}
        background={indexBackground}
        color={indexColor}
        borderColor={borderColor}
      />
      <div style={{ display: "flex", width: "100%" }}>
        {schema.map((s) => (
          <Cell
            key={s.value}
            value={s.formatFn(record)}
            background={isSelected ? selectedBackground : cellBackground}
            color={isSelected ? selectedColor : cellColor}
            borderColor={borderColor}
          />
        ))}
      </div>
    </div>
  );
};
