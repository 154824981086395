// External Imports
import { useState } from "react";

// Internal Imports
import KeywordCategorySelect from "components/forms/KeywordCategorySelect";
import Modal from "components/Modal";

const KeywordModal = ({ show, hide, onSave, onDelete }) => {
  const [category, setCategory] = useState("");
  const [keyword, setKeyword] = useState("");

  const disabled = !category || !keyword;

  const handleSave = () => {
    if (!disabled) {
      onSave({ value: keyword.toLowerCase(), category: category.toLowerCase() });
      setCategory("");
      setKeyword("");
    }
  };

  return (
    <Modal show={show} hide={hide} title={"New Keyword"} style={{ width: 400 }}>
      <div style={{ margin: "10px 0px", display: "flex", flexDirection: "column", fontSize: 18 }}>
        <input
          placeholder="Keyword (i.e. bitters)"
          style={{ width: "auto", fontSize: 16, margin: 10 }}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />

        <KeywordCategorySelect
          value={category}
          onChange={(cat) => setCategory(cat)}
          style={{ width: "auto", fontSize: 16, margin: 10 }}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-around", marginTop: 20 }}>
        <button style={{ padding: "5px 10px", fontSize: 16 }} onClick={() => onDelete()}>
          Delete
        </button>

        <button
          style={{ padding: "5px 10px", fontSize: 16, opacity: disabled ? 0.5 : 1.0 }}
          disabled={disabled}
          onClick={() => handleSave()}
        >
          Save
        </button>
      </div>
    </Modal>
  );
};

export default KeywordModal;
