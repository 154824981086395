// External Imports
import { useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";

// Internal Imports
import { useCards } from "hooks/useCards";
import DeleteModal from "components/DeleteModal";
import CardModal from "./CardModal";
import CardItem from "./CardItem";

const Cards = () => {
  const { cards, saveCard, deleteCard } = useCards();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedId, setSelectedId] = useState(false);

  const selected = cards.find((c) => c.id === selectedId);

  const handleSelect = (card) => {
    setSelectedId(card.id);
  };

  const handleSave = (values) => {
    saveCard(values);
    setShowAddModal(false);
    setSelectedId(null);
  };

  const handleDelete = () => {
    if (selectedId) {
      deleteCard(selectedId);
    }
    setShowDeleteModal(false);
    setSelectedId(null);
  };

  return (
    <>
      <CardModal
        show={showAddModal}
        hide={() => setShowAddModal(false)}
        title="New Card"
        onSave={handleSave}
        onDelete={() => setShowAddModal(false)}
      />

      <CardModal
        show={selected}
        hide={() => setSelectedId(null)}
        title={`Edit ${selected?.title || ""} Card`}
        card={selected}
        onSave={handleSave}
        onDelete={() => setShowDeleteModal(true)}
      />

      <DeleteModal
        show={showDeleteModal}
        hide={() => setShowDeleteModal(false)}
        title="Delete Card"
        onDelete={handleDelete}
      />

      <div
        style={{
          flexFlow: "column",
          display: "flex",
          width: "100%",
          padding: 30,
        }}
      >
        <div
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
            color: "white",
            fontSize: 40,
          }}
        >
          Cards
          <AiOutlinePlusCircle onClick={() => setShowAddModal(true)} />
        </div>

        <div
          style={{
            alignContent: "flex-start",
            flexWrap: "wrap",
            overflow: "auto",
            display: "flex",
            flexGrow: 1,
          }}
        >
          {cards.map((c) => (
            <CardItem key={c.id} card={c} onSelect={handleSelect} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Cards;
