// Internal Imports
import { setLoading, logError } from "state/status/actions";
import { types } from "./types";
import {
  fetchCards as fireFetchCards,
  deleteCard as fireDeleteCard,
  saveCard as fireSaveCard,
} from "services/firestore/cards";

export const fetchCards = () => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const cards = await fireFetchCards();
      dispatch({
        type: types.SET_CARDS,
        payload: cards,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem getting cards"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const saveCard = (values) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const updates = await fireSaveCard(values);
      dispatch({
        type: types.SAVE_CARD,
        payload: updates,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem saving this card"));
      }
    }

    dispatch(setLoading(false));
  };
};

export const deleteCard = (cardId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await fireDeleteCard(cardId);
      dispatch({
        type: types.DELETE_CARD,
        payload: cardId,
      });
    } catch (e) {
      console.error(e);
      if (e.name === "ElGuapoError") {
        dispatch(logError(e.message));
      } else {
        dispatch(logError("There was a problem deleting this card"));
      }
    }

    dispatch(setLoading(false));
  };
};
