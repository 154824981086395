// External Imports
import { getFirestore, collection, deleteDoc, updateDoc, getDocs, addDoc, doc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Internal Imports
import { ElGuapoError } from "errors/ElGuapoError";
import { removeUndefined } from "utils";

export const fetchVendors = async () => {
  const db = getFirestore();
  const vendorsRef = await getDocs(collection(db, "VENDORS")).catch((e) => {
    throw new ElGuapoError(e.message);
  });

  return vendorsRef.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
};

export const createVendor = async ({
  name,
  address,
  city,
  state,
  country,
  zip,
  phone,
  website,
  bitters,
  mixers,
  syrups,
  canBuy,
  canDrink,
  latitude,
  longitude,
}) => {
  const values = removeUndefined({
    name,
    address,
    city,
    state,
    country,
    zip,
    phone,
    website,
    bitters,
    mixers,
    syrups,
    canBuy,
    canDrink,
    latitude,
    longitude,
  });

  if (!values.name) {
    throw new ElGuapoError("Please enter the vendor's name.");
  }

  // update user in firestore
  const { currentUser } = getAuth();
  if (!currentUser) {
    throw new ElGuapoError("You must be logged in to add a vendor");
  }

  const vendor = {
    ...values,
    owner: currentUser.uid,
    verified: false,
    createdAt: new Date(),
    updatedAt: new Date(),
  };

  const db = getFirestore();
  const vendorRef = await addDoc(collection(db, "VENDORS"), vendor).catch((e) => {
    throw new ElGuapoError(e.message);
  });

  return { ...vendor, id: vendorRef.id };
};

export const updateVendor = async (
  vendorId,
  {
    name,
    address,
    city,
    state,
    country,
    zip,
    phone,
    website,
    bitters,
    mixers,
    syrups,
    canBuy,
    canDrink,
    latitude,
    longitude,
  }
) => {
  const values = removeUndefined({
    name,
    address,
    city,
    state,
    country,
    zip,
    phone,
    website,
    bitters,
    mixers,
    syrups,
    canBuy,
    canDrink,
    latitude,
    longitude,
  });

  if (!vendorId) {
    throw new ElGuapoError("Please select what vendor you would like to update.");
  }

  if (Object.keys.length > 0) {
    values.updatedAt = new Date();
    const db = getFirestore();
    await updateDoc(doc(db, "VENDORS", vendorId), values).catch((e) => {
      throw new ElGuapoError(e.message);
    });

    return values;
  } else {
    return {};
  }
};

export const deleteVendor = async (vendorId) => {
  if (!vendorId) {
    throw new ElGuapoError("Please select what vendor you would like to delete.");
  }

  const db = getFirestore();
  return deleteDoc(doc(db, "VENDORS", vendorId)).catch((e) => {
    throw new ElGuapoError(e.message);
  });
};
