// External Imports
import { Route, Navigate } from "react-router-dom";

// Internal Imports
import { useAuth } from "hooks/useAuth";

const RedirectRoute = () => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <Navigate to="/dashboard/home" /> : <Navigate to="/" />;
};

const redirectRoutes = [
  <Route key="dashboard" path="/dashboard" element={<Navigate to="/dashboard/home" />} />,
  <Route key="not-found" path="*" element={<RedirectRoute />} />,
];

export default redirectRoutes;
