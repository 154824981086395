// External Imports
import { MdOutlinePhotoSizeSelectActual } from "react-icons/md";

const numColumns = 3;

const CardItem = ({ card, onSelect }) => {
  if (card) {
    return (
      <div style={{ width: `${Math.floor(100 / numColumns)}%` }}>
        <div
          style={{
            position: "relative",
            textAlign: "center",
            background: "white",
            borderRadius: 15,
            fontSize: 20,
            padding: 10,
            margin: 15,
          }}
        >
          <div
            style={{
              textAlign: "left",
              paddingLeft: "5%",
              paddingTop: 5,
            }}
          >
            {card.title}
          </div>

          {card.photo?.downloadURL ? (
            <img
              src={card.photo?.downloadURL}
              alt="card"
              style={{ width: "90%", objectFit: "cover", borderRadius: 15, margin: "10px 0px" }}
              onClick={() => onSelect(card)}
            />
          ) : (
            <div
              onClick={() => onSelect(card)}
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                height: "90%",
                width: "100%",
              }}
            >
              <MdOutlinePhotoSizeSelectActual style={{ fontSize: 150 }} />
            </div>
          )}

          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div style={{ display: "flex" }}>
              Status:
              <div style={{ color: card.isAvailable ? "green" : "red", marginLeft: 5 }}>
                {card.isAvailable ? "Availabile" : "Unavailable"}
              </div>
            </div>

            <div style={{ display: "flex" }}>
              Inventory:
              <div style={{ color: card.inventory && card.inventory > 6 ? "green" : "red", marginLeft: 5 }}>
                {card.inventory || 0}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default CardItem;
