// Internal Imports
import ScreenBlur from "components/ScreenBlur";
import { useStatus } from "hooks/useStatus";

const ErrorModal = () => {
  const { error, clearError } = useStatus();

  if (error) {
    return (
      <ScreenBlur background="black" opacity={0.25}>
        <div
          style={{
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            display: "flex",
            background: "white",
            borderRadius: 10,
            padding: 20,
            height: 350,
            width: 450,
          }}
        >
          <img src="/imgs/guapoLogo.png" alt="error" style={{ width: 350, marginTop: 0, marginBottom: 0 }} />
          <p style={{ fontSize: 20 }}>{error}</p>
          <button
            style={{
              border: "1px solid black",
              borderRadius: 5,
              marginTop: 10,
              width: 100,
              fontSize: 18,
              padding: 5,
            }}
            onClick={() => clearError()}
          >
            Got it!
          </button>
        </div>
      </ScreenBlur>
    );
  } else {
    return null;
  }
};

export default ErrorModal;
