// External Imports
import { useRef } from "react";

// Core Imports
import { generateID } from "utils";

const UploadButtonWrapper = ({
  onChange,
  multiple = false,
  maxFiles = null,
  disabled = false,
  accept = ["audio/*", "video/*", "image/*"],
  style = {},
  children,
}) => {
  const inputRef = useRef(null);

  const openFileChooser = () => {
    if (inputRef.current && !disabled) {
      inputRef.current.value = null;
      inputRef.current.click();
    }
  };

  const handleSelect = (e) => {
    e.preventDefault();
    var files = maxFiles ? e.target.files.slice(0, maxFiles) : e.target.files;
    if (multiple === false && files.length > 1) {
      files = [files[0]];
    }

    const newfiles = [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (file.type) {
        file.id = generateID();
        file.downloadURL = window.URL.createObjectURL(file);
        newfiles.push(file);
      }
    }

    onChange(newfiles);
  };

  return (
    <div onClick={() => openFileChooser()} style={style}>
      {children}

      <input
        ref={inputRef}
        type="file"
        accept={accept ? accept.join() : ""}
        multiple={multiple}
        name="file-upload"
        style={{ display: "none" }}
        onChange={(e) => handleSelect(e)}
      />
    </div>
  );
};

// const btnStyle = {
//   background: "rgba(0,0,0,.12)",
//   justifyContent: "center",
//   alignItems: "center",
//   display: "flex",
//   width: "100%",
//   borderRadius: 6,
//   fontWeight: 700,
//   fontSize: 16,
// };

export default UploadButtonWrapper;
