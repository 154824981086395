// External Imports
import { getFirestore, collection, deleteDoc, getDocs, setDoc, doc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Internal Imports
import { removeUndefined, generateID, toDatetime } from "utils";
import { ElGuapoError } from "errors/ElGuapoError";
import { uploadFile } from "services/storage";

export const fetchBlogs = async () => {
  const db = getFirestore();
  const blogsRef = await getDocs(collection(db, "BLOGS")).catch((e) => {
    throw new ElGuapoError(e.message);
  });

  return blogsRef.docs.map((doc) => ({
    ...doc.data(),
    updatedAt: toDatetime(doc.data().updatedAt),
    id: doc.id,
  }));
};

export const saveBlog = async ({ id, title, author, keywords, products, tags, photo, content }) => {
  const { currentUser } = getAuth();
  if (!currentUser) {
    throw new ElGuapoError("You must be logged in to save a blog");
  }

  const blogId = id || generateID();
  const values = removeUndefined({
    title,
    author,
    keywords,
    products,
    tags,
    photo,
    content,
  });

  if (Object.keys(values).length > 0) {
    if (values.photo && !values.photo.uploadedAt) {
      values.photo = await uploadFile(values.photo, `blogs/${blogId}`);
    }

    values.updatedAt = new Date();
    const db = getFirestore();
    await setDoc(doc(db, "BLOGS", blogId), values, { merge: true }).catch((e) => {
      throw new ElGuapoError(e.message);
    });

    return { ...values, id: blogId };
  } else {
    return {};
  }
};

export const deleteBlog = async (blogId) => {
  if (!blogId) {
    throw new ElGuapoError("Please select what blog you would like to delete.");
  }

  const db = getFirestore();
  return deleteDoc(doc(db, "BLOGS", blogId)).catch((e) => {
    throw new ElGuapoError(e.message);
  });
};
