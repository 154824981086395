// Internal Imports
import { types } from "./types";

export const setLoading = (loading) => ({
  type: types.IS_LOADING,
  payload: loading,
});

export const logError = (error) => ({
  type: types.LOG_ERROR,
  payload: error,
});

export const clearError = () => ({
  type: types.CLEAR_ERROR,
});
