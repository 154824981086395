// External Imports
import React, { useState } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

// Internal Imports
import Marker from "./Marker";
import Map from "./Map";

const render = (status) => {
  switch (status) {
    case Status.LOADING:
      return null;

    case Status.FAILURE:
      return null;

    default:
      return null;
  }
};

const newOrleans = { lat: 29.9511, lng: -90.0715 };
const zoom = 14;

const GoogleMap = ({ locations, style, onClick }) => {
  const [map, setMap] = useState(null);

  return (
    <Wrapper apiKey={"AIzaSyBJ7nzSVGBJ0Wy5jNjusB59EKDPJQ3tghw"} render={render}>
      <Map
        map={map}
        setMap={setMap}
        zoom={zoom}
        center={newOrleans}
        styles={mapStyles}
        zoomControl={false}
        rotateControl={true}
        mapTypeControl={false}
        fullscreenControl={false}
        streetViewControl={false}
        style={style}
      >
        {locations.map((l, idx) => (
          <Marker key={l.id} index={idx} location={l} map={map} onClick={onClick} />
        ))}
      </Map>
    </Wrapper>
  );
};

const mapStyles = [
  {
    featureType: "poi",
    elementType: "labels",
    // stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
];

export default GoogleMap;
